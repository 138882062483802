
const LocationSort = (oldArray) => {

    let array = [];
    let newObject = {};

    //Filter null or undefined values
    array = oldArray.filter(x => x);

    array.forEach(element => {
        newObject[`${element.name}`] = element;
    });

    return newObject;
};

export default LocationSort;