import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import { withRouter } from 'react-router';
import { CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { TextField, Button, Link, FormControl } from '@material-ui/core';

import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../UserPool";

const useStyles = makeStyles((theme) => ({
    LoginPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
    },
    LoginFormContainer: {
        display: 'flex',
        maxWidth: 400,
        flexShrink: 1,
        maxHeight: 600,
        justifyContent: 'center',
        alignItems: 'center',
    },
    CardHeader: {
        padding: 30,
        display: 'block',
    },
    TextField: {
        display: 'flex',
        minWidth: 300,
        flexShrink: 1,
        marginBottom: 30,
    },
    Button: {
        display: 'flex',
        height: 45,
        marginTop: 50,
        marginBottom: 20,
        backgroundColor: '#00378b',
        color: 'white',
        borderRadius: '%',
    },
    title: {
        flexGrow: 1,
        color: '#00378b',
        fontWeight: 'Bold',
      },
    subtitle: {
        marginTop: 20,
    },
    message: {
        color: '#ff2400',
    },
    hints: {
        fontSize: 'x-small',
        color: 'gray',
        marginTop: -20,
        marginBottom: -35,
    },
    hintList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    footer: {
        position: "absolute",
        bottom: 8,
        left: "50%",
        translate: "-50%",
    }
}));



function LoginPage() {

    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [onPasswordChange, setOnPasswordChange] = useState(false);
    const [message, setMessage] = useState("");



    function handleSubmit(event) {
        event.preventDefault();
        
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        });
    
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        }); 

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                setOnPasswordChange(false);
                window.location.assign("/");
            },
            onFailure: data => {
                setOnPasswordChange(false);
                setMessage(data.message);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                setOnPasswordChange(true);
            }
        });
    };

    function handleNewPasswordChallenge (event) {
        event.preventDefault();
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        });
    
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        }); 

        if(newPassword === confirmPassword){
            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    setOnPasswordChange(false);
                },
                onFailure: data => {
                    setOnPasswordChange(false);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    setOnPasswordChange(true);
                    delete userAttributes.email_verified;
                    delete userAttributes.phone_number_verified;
                    user.completeNewPasswordChallenge(newPassword, userAttributes, {
                        onSuccess: result => {
                            window.location.assign("/");
                        },
                        onFailure: err => {
                            throw err;
                        }
                    });
                }
            });
        }
        else{
            setMessage("Passwords do not match!");
        }
    };

    return(
        <div className={classes.LoginPage}>
            <Card raised={true} className={classes.LoginFormContainer}>
               <div className="FormContainer">
               
                <CardHeader
                        title={
                            <Typography variant="h3" className={classes.title}>
                                myPFEIFER
                            </Typography>}
                        subheader={
                            onPasswordChange
                            ?<Typography className={classes.subtitle} variant="h4">
                                CHANGE PASSWORD
                            </Typography>:
                            <Typography className={classes.subtitle} variant="h4">
                                LOGIN
                            </Typography>}
                        className={classes.CardHeader}
                    />
                    <CardContent>
                        {onPasswordChange
                        ?<form onSubmit={handleNewPasswordChallenge}>
                            <FormControl>                            
                                <div>
                                    <TextField
                                        type="password" id="newPasswordInput" required={true} className={classes.TextField} label="new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)} />
                                </div>
                                <div>
                                    <TextField
                                        type="password" id="confirmPasswordInput" required={true} className={classes.TextField} label="confirm new password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>
                                <div >
                                    <Typography className={classes.message} >{message}</Typography>
                                </div>
                               <Card raised={false} className={classes.hints}>
                                    <ul className={classes.hintList}>
                                        <li>The password must be at least 8 characters long</li>
                                        <li>The password must contain at least one special character </li>
                                        <li>The password must contain at least one capital letter </li>
                                        <li>The password must contain at least one number </li>
                                    </ul>
                               </Card>
                                <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                    CHANGE PASSWORD
                                </Button>
                            </FormControl>
                        </form>
                        :<form onSubmit={handleSubmit}>
                            <FormControl>
                                <div>
                                    <TextField
                                        type="text" id="usernameInput" required={true} className={classes.TextField} label="Username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)} />
                                </div>
                            
                                <div>
                                    <TextField
                                        type="password" id="passwordInput" required={true} className={classes.TextField} label="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div >
                                    <Typography className={classes.message} >{message}</Typography>
                                </div>
                                <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                    Login
                                </Button>
                            </FormControl>
                        </form>}
                    </CardContent>
                    <CardContent>
                        Forgot <Link href="login/forgotPassword">Password</Link>?
                    </CardContent>
               </div>
            </Card>
            <div className={classes.footer}>
                <div>
                    © PEIFER Systems GmbH, Satteins, Austria
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginPage);

