//React imports
import React from 'react';
import { withRouter } from "react-router-dom";

//Material-UI imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

//axios
import axios from 'axios';

//getUserGroup
import getUserGroup from '../func/cognito/getUserGroup';

//Actions
import { get_ID_Token }  from '../func/cognito/get_Token';

import { useRecoilState } from 'recoil';

//Other
import {unitState, unitNameState, userDataState, selectedLocationDataState, locationDataState, timeRangeState} from '../recoil/Atoms';
import useSWR from "swr";
import ParseUserData from '../func/ParseUserData';
import LogContainer from '../components/Log_Container';

import { UserDataDropdown } from '../components';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import CalendarIcon from '@material-ui/icons/DateRange';

import CalcTimeDifference from "../func/CalcTimeDifference";
import CalcIntervalS from "../func/CalcIntervalS";


const useStyles = makeStyles({
    log: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    IconColor: {
        color: "#00378b",
    },
    LogContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: 30,
        minWidth: '50vw',
        marginTop: 10,
    },
    header: {
        backgroundColor: '#00378b',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 1050,
        flexGrow: 1,
        color: 'white',
    },
    title: {
        color: 'white',
    },
    SubTitle: {
        color: 'white',
    },
    ValueName: {
      color: '#00378b',
    },
    Card:{
        paddingBlock: 20,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-evenly',
        overflow: 'visible',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    TreeView: {
        display: 'flex',
        maxHeight: 200,
        flexGrow: 1,
        maxWidth: 200,
        alignItems: 'center',
        marginRight: 20,
    },
    Icon: {
        color: '#00378b',
    },
    listitem: {
        height: 25,
        color: '#00378b',
    },
    link: {
        color: "#00378b",
        fontSize: 17,
        display: 'block',
        width: 100,
        textAlign: 'center',
    },
    loadingCircle: {
        color: 'white',
    },
    CardHeader: {
        marginTop: -25,
        color: "#00378b",
    },
    circleContainer: {
        marginTop: 100,
        marginBottom: 100,
    },
    selectionMessage: {
        color: '#00378b',
    },
    rangeSelection: {
        marginRight: 30,
    },
    rangeController: {
        minWidth: 120,
    },
    Message: {
        marginBottom: 10,
    },
    selectionContainer_nowrap: {
        display: 'flex',
        marginTop: 20,
        justifyContent: "space-around",
        marginBottom: 20,
    },
    unitInformation: {
        padding: 15,
    },
    informationCard: {

    },
    fontColor: {
        color: "#00378b",
    },
});

const fetcher = config => axios.get(config.url, 
    {
        mehtod: 'GET',
        headers: {
            'Access-Control-Allow-Origin': config.origin,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': 'Bearer ' + get_ID_Token()
    }
}).then(res => res.data);
  
function Log(props) {

    const classes = useStyles();

    const group = getUserGroup();

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000"); 
    const isProduction = pathname.includes("mypfeifer.com");

    const [locationData, setLocationData] = useRecoilState(locationDataState);
    const [selectedLocation, setSelectedLocation] = useRecoilState(selectedLocationDataState);
    const [range, setRange] = useRecoilState(timeRangeState);

    const [unit, setUnit] = useRecoilState(unitState); // should store the global state of the selected unit

    const [unitName, setUnitName] = useRecoilState(unitNameState);

    const [userDataGlobal, setUserDataGlobal] = useRecoilState(userDataState);

    const [key, setKey] = React.useState(Math.random());
    const [interval, setIntervalS] = React.useState(0);

    let config_UserData = {};

    if(isLocal) {
        config_UserData['url'] = `http://localhost:8080/userdata/${group}`;
        config_UserData['origin'] = 'http://localhost:8080/userdata/';
    }
    else if(isProduction) {
        config_UserData['url'] = `https://server.mypfeifer.com/userdata/${group}`;
        config_UserData['origin'] = `https://server.mypfeifer.com/userdata/*`;
    }
    else {
        config_UserData['url'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/userdata/${group}`;
        config_UserData['origin'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/userdata/*`;
    }

    // Fetch UserData
    useSWR(config_UserData, fetcher, {
        onSuccess: (data, key, config) => {
            var parsedData = ParseUserData(data);
            setLocationData(parsedData.locationDataArray);
            setUserDataGlobal(parsedData.treedata);
            setKey(Math.random());
        }
    });

    const handleSelect = (value) => {
        setUnitName(value.name);
        setUnit(value.name);
        setSelectedLocation(value);
        setKey(Math.random());
    }

    const handleTimeChange = (timespan) => {
        timespan[0].setHours(0, 0, 0, 0);
        timespan[1].setHours(23,59,0,0);
        setRange(timespan);
        setIntervalS(CalcIntervalS(CalcTimeDifference(timespan[0], timespan[1])));
    };

    if(unit!=='' && Object.keys(userDataGlobal).length !== 0 && selectedLocation.units.length !== 0) {
        return (
            <div className="Data">
                <Card className={classes.Card} raised={false}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    <DateTimeRangePicker
                        className={classes.DatePicker}
                        onChange={handleTimeChange}
                        value={range}
                        format="dd-MM-yyyy"
                        rangeDivider={" to "}
                        calendarAriaLabel="Pick time range"
                        calendarIcon={<CalendarIcon className={classes.IconColor}/>}
                        clearIcon={null}
                    />
                </Card>
                <div className={classes.selectionContainer_nowrap}>
                    <Card raised={true} className={classes.informationCard}>
                        <Typography className={classes.unitInformation}>
                            <strong className={classes.fontColor}>Selected Location: </strong> {unitName}
                        </Typography>
                    </Card>
                </div>
                <div>
                    <LogContainer key={key} interval={interval} locationName={unitName} units={selectedLocation.units} timerange={range}/>
                </div>
            </div>
        );
    }
    else if(unit==='' && Object.keys(userDataGlobal).length !== 0) {
        return(
            <div>
                <Card className={classes.Card} raised={false}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    <DateTimeRangePicker
                        className={classes.DatePicker}
                        onChange={handleTimeChange}
                        value={range}
                        format="dd-MM-yyyy"
                        rangeDivider={" to "}
                        calendarAriaLabel="Pick time range"
                        calendarIcon={<CalendarIcon className={classes.IconColor}/>}
                        clearIcon={null}
                    />
                </Card>
                <div className={classes.circleContainer}>
                    <Typography variant="h5" className={classes.selectionMessage}>
                        Please select a Device!
                    </Typography>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={classes.circleContainer}>
                <CircularProgress className={classes.loadingCircle}/>
            </div>
        );
    }
}

export default withRouter(Log);