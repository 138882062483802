
import React from 'react';
import { withRouter } from 'react-router-dom';


function Condition(props) {

    const [key, setKey] = React.useState(Math.random());

    React.useEffect(() => {
        setKey(Math.random());
    }, [props.condition])

    if(props.condition){
        return <div key={key}>{props.true}</div>
    }
    else{
        return <div key={key}>{props.false}</div>
    }
}

export default withRouter(Condition);