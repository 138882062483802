import UserPool from "../../UserPool";

const isAuthenticated = () => {
    
    let loggedIn = false;

    const cognitoUser = UserPool.getCurrentUser();

    if (cognitoUser != null) {
        cognitoUser.getSession(function(err, session) {
          if ( err ) {
            console.log(err);
            loggedIn = false;
          }
          else if( session.isValid() ){
           loggedIn = true;
          }
        });
    }
    else{
        loggedIn = false;
    }
    return loggedIn;
};

export default isAuthenticated;