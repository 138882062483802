import UserPool from "../../UserPool";

const AttributeVerification = (attributeName, verificationCode) => {

    const user = UserPool.getCurrentUser();

    if(user != null) {
        user.getSession((err, result) => {
            if(err) {
                alert(err);
                console.log("getSession", err);
                return;
            }
        });

        user.verifyAttribute(attributeName, verificationCode, {
            onSuccess: (result) => {
                console.log("verified", result);
            },
            onFailure: (err) => {
                alert(err);
            },
        });

    }
};

export default AttributeVerification;