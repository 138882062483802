//React
import React from "react";
import { withRouter } from "react-router-dom";

//Axios
import axios from 'axios';

//Material-UI
import { makeStyles } from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import { CircularProgress, Typography } from "@material-ui/core";

import './index.css';

//Rechart
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//Other
import { get_ID_Token }  from '../func/cognito/get_Token';
import Timestamp_to_Time_Conversion from "../func/Timestamp_to_Time_Conversion";
import ParseToInteger from "../func/ParseToIntgeger";


const useStyles = makeStyles((theme) => ({
    componentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    Card: {
        display: 'flex',
        minWidth: '97%',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 10,
     },
    lineChart: {
        marginLeft: -10,
    },
    circleContainer: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fabContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    fab: {
        marginRight: 20,
        background: '#00378b',
        color: 'white',
    },
    DialogTitle: {
        color: '#00378b',
    },
    SelectionMenu: {
        width: '75%',
        
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
    },
    formGroup: {
        marginTop: -25,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    checkBox: {
        color: '#00378b', 
        '&.Mui-checked': {
            color: '#00378b'
        },
    },
    spacing: {
        padding: 20,
    },
    title: {
        textAlign: "center",
        color: '#00378b',
    },
    button: {
        backgroundColor: "#00378b",
        color: "white",
        left: "50%",
        transform: "translateX(-50%)",
    },
    selectionContainer_nowrap: {
        display: 'flex',
        marginTop: 20,
        justifyContent: "space-around",
    },
    selectionContainer_wrap: {
        display: "flex",
        flexDirection: "column",
	    marginTop: 20,
	    justifyContent: "center",
	    flexWrap: "wrap",
    },
    rangeSelection: {
        marginTop: 20,
    },
    rangeController: {
        minWidth: 120,
    },
    bDownLoad: {
        backgroundColor: "#00378b",
        color: "white",
        top: "15%",
    },
    unitInformation: {
        padding: 15,
    },
    informationCard: {
        marginTop: 20,
    },
    fontColor: {
        color: "#00378b",
    },
    cardTitle: {
        fontSize: 15,
        color: "#00378b",
        marginBlock: 5,
        fontWeight: 'bold',
    },
    header: {
        marginBlock: 5,
    }
}));

const controller = new AbortController();

const fetcher = async (request, onSuccess) => {
    //let responses = {};
    let response = [];

    //Console.log The Requests in the Fetchermethod
    //console.log("Requests in Fetcher-Method: " + allRequests);

    if(request === undefined) {
        throw new Error('Request data is not existing!');
    }

    //console.log(allRequests);

    response  = await axios.get(request, {
        mehtod: 'GET',
        signal: controller,
        headers: {
            'Authorization': 'Bearer ' + get_ID_Token()
        }
    }).then(res => {
        return res.data;
    })

    //Console.log the responseData at the end of the Fetcher-Method
    //console.log("ResponseData at the end of the Fetcher-Methode: " + JSON.stringify(responses));
    
    if(response !== null && response !== undefined && response.length !== 0) {
        onSuccess(true, response);
    }
    else {
        onSuccess(false, null);
    }
}

//LineChart Component-Function
function LineChartComponent(props){

    const classes = useStyles();

    const [responseData, setResponseData] = React.useState(null);
    const [onSuccess, setOnSucess] = React.useState(false);

    React.useEffect(() => {
        let cancel = false;
        setOnSucess(false);
        try{
            fetcher(props.request, (success, data) => {
                if (cancel)
                    return;
                if(success){
                    //console.log(data);
                    //console.log("Success");
                    setResponseData(ParseToInteger(Timestamp_to_Time_Conversion(data)));
                    setOnSucess(true);
                }
                else {
                    //console.log("Fail");
                    setResponseData(null);
                }
            });
        }
        catch(e) {
            console.log(e.message);
        }

        return () => {
            controller.abort();
            cancel = true;
        }
    },[props.request]);

    var name = "";
    if(props.nameMapping.hasOwnProperty(props.measureName))
        name = props.nameMapping[props.measureName];
    else
        name = props.measureName;

    return (
        <div className={classes.componentContainer}>
            <Card key={props.measureName} raised={true} className={classes.Card}>
                {onSuccess ? 
                    <Typography className={classes.cardTitle}>
                            {name}
                    </Typography> 
                : null}
                <ResponsiveContainer width="100%" height={200}>
                    {onSuccess && responseData !== null
                        ?<LineChart className={classes.lineChart} data={responseData} scaleToFit={true}>
                            <CartesianGrid strokeDasharray="6 6"/>
                            <XAxis  dataKey="timestamp"/>
                            <YAxis type="number" domain={[0, dataMax => (Math.round(dataMax) + 10)]} allowDecimals={false} allowDataOverflow={true}/>
                            <Tooltip/>
                            <Legend/>
                            <Line 
                                type="monotone" 
                                dataKey={`value`} 
                                stroke="#00378b" 
                                activeDot={{r:2}} 
                                legendType="square"
                                dot={false}
                                strokeWidth={1} 
                                unit={responseData[1].unit}
                                connectNulls
                            />
                        </LineChart>
                        : <div className={classes.circleContainer}>
                            <CircularProgress className={classes.loadingCircle}/>
                        </div>
                    }
                </ResponsiveContainer>
            </Card>
        </div>
    );
}

export default withRouter(LineChartComponent);