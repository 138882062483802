//React
import React from "react";
import { withRouter } from "react-router-dom";

//Material-UI
import { makeStyles } from "@material-ui/styles";
import { Backdrop, Box } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    Backdrop: {
        display: 'flex',
        zIndex: 1,
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    Typo: {
        color: 'white',
        lineHeight: '70px',
    },
    Progress: {
        color: '00378b',
    }
}));



//LineChart Component-Function
function CSV_Progress(props){

    const classes = useStyles();

    const [open, setOpen] = React.useState(props.active);

    React.useEffect(() => {
        setOpen(props.active);
    }, [props.active])

    return(
        <>
            <Backdrop
                sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                className={classes.Backdrop}
            >
                <Typography className={classes.Typo}>CSV-Download is in progress</Typography>
                <Box sx={{ width: '80%' }}>
                    <LinearProgress className={classes.Progress}/>
                </Box>
            </Backdrop>
        </>
    );
}

export default withRouter(CSV_Progress);