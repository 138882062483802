import React, {useState} from 'react';
import { withRouter } from "react-router-dom";

import { Card, CardContent, makeStyles, Typography, Divider } from '@material-ui/core';
import { TextField, Button, FormControl } from '@material-ui/core';

import ChangePassword from '../func/cognito/ChangePassword';
import ChangeEmail from '../func/cognito/ChangeEmail';
import ChangePhoneNumber from '../func/cognito/ChangePhoneNumber';
import AttributeVerification from '../func/cognito/AttributeVerification';
import { Notification_Settings } from '../components';

const useStyles = makeStyles((theme) => ({
    profile: {
        display: 'flex',
        justifyContent: 'center',
    },
    Card: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 500,
        flexGrow: 1,
        marginTop: 50,
        marginBottom: 50,
    },
    container:{

    },
    TextField: {
        display: 'flex',
        minWidth: 300,
        flexShrink: 1,
        marginBottom: 30,
    },
    Button: {
        display: 'flex',
        height: 45,
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: '#00378b',
        color: 'white',
        borderRadius: '%',
    },
    divider: {
        backgroundColor: '#808080',
    },
    successMessage: {
        color: '#249225',
    },
    errMessage: {
        color: '#ff2400',
    },
}));


function Settings(props) {

    const classes = useStyles();

    const [newEmail, setNewEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState("");

    const [passwordMessage, setPasswordMessage] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [phoneNumberMessage, setPhoneNumberMessage] = useState("");

    const [confirmationCode, setConfirmationCode] = useState("");
    
    const [passwordFailure, setPasswordFailure] = useState(false);
    const [emailFailure, setEmailFailure] = useState(false);
    const [phoneNumberFailure, setPhoneNumberFailure] = useState(false);

    const [onConfirmation, setOnConfirmation] = useState(false);

    const handlePasswordChange = (event) => {
        event.preventDefault();

        if(confirmPassword === newPassword){
            ChangePassword(oldPassword, newPassword);
            setPasswordFailure(false);
            setPasswordMessage("Password change successful!");
        }
        else{
            setPasswordFailure(true);
            setPasswordMessage("Passwords do not match!");
        }
    };

    const handleEmailChange = (event) => {
        event.preventDefault();

        if(newEmail === confirmEmail) {
            ChangeEmail(newEmail);
            setEmailFailure(false);
            setOnConfirmation(true);
        }
        else{
            setEmailFailure(true);
            setOnConfirmation(false);
            setEmailMessage("Emails do not match!");
        }
    };

    const handleConfirmEmail = (event) => {
        event.preventDefault();

        AttributeVerification('email', confirmationCode);
        setOnConfirmation(false);
        setEmailMessage("Email change successful!");
    };

    const handlePhoneNumberChange = (event) => {
        event.preventDefault();

        if(newPhoneNumber === confirmPhoneNumber) {
            ChangePhoneNumber(newPhoneNumber);
            setPhoneNumberFailure(false);
            setPhoneNumberMessage("Email change successful!");
        }
        else{
            setPhoneNumberFailure(true);
            setPhoneNumberMessage("Phone numbers do not match!");
        }
    };

    return (
        <div className={classes.profile}>
            <Card raised={true} className={classes.Card}>
                <div className={classes.container}>
                    <CardContent>
                        <Typography variant="h4">
                            Settings
                        </Typography>
                    </CardContent>                    
                    <CardContent>
                        <Typography variant="h6">
                            Change Email
                        </Typography>
                        <form onSubmit={handleEmailChange}>
                            <FormControl>                            
                                <div>
                                    <TextField
                                        type="text" id="newEmailInput" required={true} className={classes.TextField} label="new email"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)} />
                                </div>
                                <div>
                                    <TextField
                                        type="text" id="confirmEmailInput" required={true} className={classes.TextField} label="confirm new email"
                                        value={confirmEmail}
                                        onChange={(e) => setConfirmEmail(e.target.value)} />
                                </div>
                                {emailFailure
                                    ?<div >
                                        <Typography className={classes.errMessage} >{emailMessage}</Typography>
                                    </div>
                                    :<div >
                                        <Typography className={classes.successMessage} >{emailMessage}</Typography>
                                    </div>
                                }
                                <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                    Change Email
                                </Button>
                            </FormControl>
                        </form>
                        {onConfirmation &&
                            <div>
                                <Typography variant="h6">
                                    Confirm Email
                                </Typography>
                                <form onSubmit={handleConfirmEmail}>
                                    <FormControl>
                                        <div>
                                            <TextField
                                                type="text" id="newEmailInput" required={true} className={classes.TextField} label="verification code"
                                                value={confirmationCode}
                                                onChange={(e) => setConfirmationCode(e.target.value)} />
                                        </div>
                                        <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                            confirm
                                        </Button>
                                    </FormControl>
                                </form>
                            </div>
                        }
                    </CardContent>
                    <Divider component="hr" className={classes.divider}/>
                    <CardContent>
                        <Typography variant="h6">
                            Change Password
                        </Typography>
                        <form onSubmit={handlePasswordChange}>
                            <FormControl>                            
                                <div>
                                    <TextField
                                        type="password" id="newPasswordInput" required={true} className={classes.TextField} label="old password"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)} />
                                </div>
                                <div>
                                    <TextField
                                        type="password" id="confirmPasswordInput" required={true} className={classes.TextField} label="new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)} />
                                </div>
                                <div>
                                    <TextField
                                        type="password" id="confirmPasswordInput" required={true} className={classes.TextField} label="confirm new password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>
                                {passwordFailure
                                    ?<div >
                                        <Typography className={classes.errMessage} >{passwordMessage}</Typography>
                                    </div>
                                    :<div >
                                        <Typography className={classes.successMessage} >{passwordMessage}</Typography>
                                    </div>
                                }
                                <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                    Change Password
                                </Button>
                            </FormControl>
                        </form>
                    </CardContent>
                    <Divider component="hr" className={classes.divider}/>
                    <CardContent>
                        <Typography variant="h6">
                            Change Phone Number
                        </Typography>
                        <form onSubmit={handlePhoneNumberChange}>
                            <FormControl>                            
                                <div>
                                    <TextField
                                        type="text" id="newPhoneNumberInput" required={true} className={classes.TextField} label="new phone number"
                                        value={newPhoneNumber}
                                        onChange={(e) => setNewPhoneNumber(e.target.value)} />
                                </div>
                                <div>
                                    <TextField
                                        type="text" id="confirmPhoneNumberInput" required={true} className={classes.TextField} label="confirm new phone number"
                                        value={confirmPhoneNumber}
                                        onChange={(e) => setConfirmPhoneNumber(e.target.value)} />
                                </div>
                                {phoneNumberFailure
                                    ?<div >
                                        <Typography className={classes.errMessage} >{phoneNumberMessage}</Typography>
                                    </div>
                                    :<div >
                                        <Typography className={classes.successMessage} >{phoneNumberMessage}</Typography>
                                    </div>
                                }
                                <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                    Change Phone Number
                                </Button>
                            </FormControl>
                        </form>
                    </CardContent>
                    <Divider component="hr" className={classes.divider}/>
                    <Notification_Settings/>
                </div>
            </Card>
        </div>
    );

}

export default withRouter(Settings);
