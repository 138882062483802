//React imports
import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";

//Material-UI imports
import { makeStyles } from '@material-ui/core/styles';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import { Typography } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import LogComponent from './Log_Component';
import {CircularProgress} from '@material-ui/core';

import axios from 'axios';
import { get_ID_Token } from '../func/cognito/get_Token';
import getUserGroup from '../func/cognito/getUserGroup';


const useStyles = makeStyles({
    fontColor: {
        color: "#00378b",
    },
    IconColor: {
        color: "#00378b",
    },
    AccordionDetails: {
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    loadingCircle: {
        color: 'black',
    },
    circleContainer: {
        marginTop: 200,
    }
});

const fetchMappings = async (group, location)  => {
    const windowLocation = window.location.href;
    const pathname = windowLocation.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    var url_namemapping = "";
    var url_valuemapping = "";

    if(isLocal){
        url_namemapping = `http://localhost:8080/nameMapping/location/${group}/${location}`;
        url_valuemapping = `http://localhost:8080/valueMapping/location/${group}/${location}`;
    }
    else if(isProduction) {
        url_namemapping = `https://server.mypfeifer.com/nameMapping/location/${group}/${location}`;
        url_valuemapping = `https://server.mypfeifer.com/valueMapping/location/${group}/${location}`;
    }
    else{
        url_namemapping = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/nameMapping/location/${group}/${location}`;
        url_valuemapping = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/valueMapping/location/${group}/${location}`;
    }
    
    return {
        nameMapping: await axios.get(url_namemapping, {
            mehtod: 'GET',
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        }),
        valueMapping: await axios.get(url_valuemapping, {
            mehtod: 'GET',
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        })
    }
}
  
function LogContainer(props) {

    const classes = useStyles();

    const [units, setUnits] = React.useState([""]);
    const [timerange, setTimerange] = React.useState(0);
    const [key, setKey] = React.useState(Math.random());

    useEffect(() => {
        setUnits(props.units);
        setTimerange(props.timerange);
        setKey(Math.random());
    }, [props.units, props.timerange, props.interval]);

    const [isFinished, setIsFinished] = React.useState(false);
    const [responseData, setResponseData] = React.useState();

    useEffect(() => {
        if(isFinished === false) {
            fetchMappings(getUserGroup(), props.locationName).then(res => {
                setResponseData(res);
                setIsFinished(true);
            });
        }
    },[props.locationName, isFinished]);

    if (isFinished && typeof responseData !== 'undefined') {
        return(
            <div className='LogContainer'>
                {units.map(unit => (
                    <Accordion key={unit.id} TransitionProps={{ unmountOnExit: true, timeout: 500 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <DescriptionIcon className={classes.IconColor}></DescriptionIcon>
                            <Typography sx={{ width: '33%', flexShrink: 0, color: '#00378b'}} className={classes.fontColor}>
                                <strong>{unit.name}</strong>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.AccordionDetails}>
                            <LogComponent 
                                key={key} 
                                unit={unit} 
                                timerange={timerange} 
                                nameMapping={responseData.nameMapping}
                                valueMapping={responseData.valueMapping}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        )
    }
    else{
        return(
            <div>
                <div className={classes.circleContainer}>
                    <CircularProgress className={classes.loadingCircle}/>
                </div>
            </div>
        );
    }
}

export default withRouter(LogContainer);