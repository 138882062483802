//React imports
import React from 'react';
import { withRouter } from 'react-router';

//Axios
import axios from 'axios';

//SWR
import useSWR from 'swr';

//Component imports
import ValueCard from './ValueCard'

//Material-UI imports
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { CardHeader, makeStyles, Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

//functions imports
//import ArraySort_BooleanFirst from '../func/ArraySort_BooleanFirst';
import LocationSort from '../func/LocationSort';
import { get_ID_Token }  from '../func/cognito/get_Token';
import { useEffect } from 'react';

const useStyles = makeStyles({
    Card:{
        padding: 14,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    valueContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
    header: {
        padding: 0,
        paddingLeft: 20,
    },
    headerTitle: {
        fontSize: 23,
        color: "#000000",
    },
    Item: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    loadingCircle: {
        color: 'black',
    },
    circleContainer: {
        marginTop: 200,
    },
    unitInformation: {
        padding: 15,
    },
    informationCard: {
        marginTop: 20,
    },
    fontColor: {
        color: "#00378b",
    },
    selectionContainer_nowrap: {
        display: 'flex',
        marginTop: 20,
        justifyContent: "space-around",
        marginBottom: 20,
    },
    selectionContainer_wrap: {
        display: "flex",
        flexDirection: "column",
	    marginTop: 20,
	    justifyContent: "center",
	    flexWrap: "wrap",
        marginBottom: 20,
    },
    headerSubtitle: {
        alignSelf: 'center',
        marginLeft: 30,
        color: 'black',
        fontSize: 13,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    ValueSheet: {
        marginTop: 20,
    }
});


const fetcher = config => axios.get(config.url, {
    mehtod: 'GET',
    headers: {
        'Access-Control-Allow-Origin': config.origin,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': 'Bearer ' + get_ID_Token()
    }
}).then(res => LocationSort(res.data, config.measureNames));

function ValueSheet(props){

    const classes = useStyles();

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    let config = {};

    const [key, setKey] = React.useState();

    useEffect(() => {
        setKey(Date.now());
    }, [props]);


    if(isLocal){
        config['url'] = `http://localhost:8080/unitdata/${props.unit}`;
        config['origin'] = "localhost:8080/unitdata/";
    }
    else if(isProduction) {
        config['url'] = `https://server.mypfeifer.com/unitdata/${props.unit}`;
        config['origin'] = "https://server.mypfeifer.com/unitdata/*";
    }
    else{
        config['url'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/unitdata/${props.unit}`;
        config['origin'] = "http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/unitdata/*";
    }

    config.measureNames = props.measureNames;

    const {data: currentData} = useSWR(config, fetcher);

    let lastUpdatedAt = "";

    if(currentData){
        var keys = Object.keys(currentData);
        lastUpdatedAt = new Date(currentData[`${keys[0]}`].timestamp*1000).toLocaleString();
    }

    const RenderValueCards = (props) => {

        const [key, setKey] = React.useState();

        useEffect(() => {
            setKey(Date.now());
        }, [props]);

        const values = props.values;
        const measureNames = props.measureNames;
        const valueCards = measureNames.map((name) => 
            <Grid item key={name}>
                {values.hasOwnProperty(name)
                    ? <ValueCard 
                            key={key}
                            value={values[`${name}`].value} 
                            unit={values[`${name}`].unit} 
                            name={values[`${name}`].name} 
                            exists={true} 
                            isBool={typeof values[`${name}`].value == "boolean"}
                            valueMapping={props.valueMapping}
                            nameMapping={props.nameMapping}/>
                    : <ValueCard 
                            key={key}
                            value={"N/A"} 
                            unit={""} 
                            name={name} 
                            exists={false} 
                            isBool={false}
                            valueMapping={props.valueMapping}
                            nameMapping={props.nameMapping}/>
                }
            </Grid>
        );

        return (
            <Grid container justifyContent="flex-start" spacing={0}>
                {valueCards}
            </Grid>
        );
    };

    if(!currentData) {
        return(
            <div className={classes.ValueSheet}>
                <div className={classes.circleContainer}>
                    <CircularProgress className={classes.loadingCircle}/>
                </div>
            </div>
        );
    }
    else {
        return(
            <div>
                <div className={classes.ValueSheet}>
                    <Card className={classes.Card} raised={true}>
                        <div className={classes.headerContainer}>
                            <CardHeader className={classes.header} title={
                                <div className={classes.titleContainer}>
                                    <Typography className={classes.headerTitle}>{props.unitName}</Typography>
                                    <Typography className={classes.headerSubtitle}>
                                        <strong> Last Updated: </strong> {lastUpdatedAt}
                                    </Typography>
                                </div>
                            }/>
                        </div>
                        <div className={classes.valueContainer}>
                            <RenderValueCards
                                key={key} 
                                measureNames={props.measureNames} 
                                values={currentData} 
                                valueMapping={props.valueMapping}
                                nameMapping={props.nameMapping}/>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withRouter(ValueSheet);