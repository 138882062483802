//UserPool
import UserPool from '../../UserPool';

const getUserName = () => {
    const cognitoUser = UserPool.getCurrentUser();

    let username = "";

    if(cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if(err) {
                console.log(err);
            }
            else if(session.isValid()) {
                username = session.idToken.payload['cognito:username'];
            }
        })
    }
    return username;
};

export default getUserName;