import { atom } from 'recoil';

function defaultDateTime() {
    const yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(23,59,0,0);

    return [yesterday, today];
}

export const unitState = atom({
    key: "unit",
    default: "",
});

export const unitNameState = atom({
    key: "unitName",
    default: ""
});

export const selectedDeviceState = atom({
    key: "device",
    default: ""
});

export const timeRangeState = atom({
    key: "timerange",
    default: defaultDateTime(),
});

export const userDataState = atom({
    key: "userData",
    default: {},
});

export const activeDataPageState = atom({
    key: "activeDataPage",
    default: {},
});

export const locationDataState = atom({
    key: "locationData",
    default: {},
});

export const selectedLocationDataState = atom({
    key: "selectedLocation",
    default: {},
});

export const linechartIndexState = atom({
    key: "linechartIndex",
    default: {},
});

export const accordionExpandedState = atom({
    key: "accordionExpanded",
    default: [],
});

