//React
import React from "react";
import { withRouter } from "react-router-dom";

//Material-UI
import { Dialog, DialogTitle } from "@material-ui/core";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { DialogActions, Button } from "@material-ui/core";


//LineChart Component-Function
function Alert(props){

    const [open, setOpen] = React.useState(props.active);

    React.useEffect(() => {
        setOpen(props.active);
    }, [props.active])

    const handleClose = () => {
        props.onClose();
    }

    return(
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withRouter(Alert);