//React imports
import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

//Material-UI imports
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';



const useStyles = makeStyles({
    ValueCard: {
        display: 'flex',
        paddingBlock: 15,
        paddingInline: 12,
    },
    ValueCardInvisible: {
        display: 'flex',
        paddingBlock: 15,
        paddingInline: 12,
        visibility: 'collapse',
    },
    Card: {
        backgroundColor: '#d3d3d3',
        width: 190,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ValueName: {
        color: '#00378b',
        fontSize: 13,
    },
    ValueText: {
        fontSize: 18,
    },
    redCard: {
        backgroundColor: '#d3d3d3',
        width: 180,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftStyle: 'solid',
        borderLeftColor: '#ff4d4f',
        borderLeftWidth: 10,
    },
    yellowCard: {
        backgroundColor: '#d3d3d3',
        width: 180,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftStyle: 'solid',
        borderLeftColor: '#faad14',
        borderLeftWidth: 10,
    },
    greenCard: {
        backgroundColor: '#d3d3d3',
        width: 180,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftStyle: 'solid',
        borderLeftColor: '#52c41a',
        borderLeftWidth: 10,
    },
    blueCard: {
        backgroundColor: '#d3d3d3',
        width: 180,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftStyle: 'solid',
        borderLeftColor: '#00378b',
        borderLeftWidth: 10,
    },
    redValueName: {
        color: '#ff4d4f',
        fontSize: 13,
    },
    yellowValueName: {
        color: '#faad14',
        fontSize: 13,
    },
    greenValueName: {
        color: '#52c41a',
        fontSize: 13,
    },
    blueValueName: {
        color: '#00378b',
        fontSize: 13,
    },
    redValueText: {
        color: '#ff4d4f',
        fontSize: 18,
    },
    yellowValueText: {
        color: '#faad14',
        fontSize: 18,
    },
    greenValueText: {
        color: '#52c41a',
        fontSize: 18,
    },
    blueValueText: {
        color: '#00378b',
        fontSize: 18,
    },
    loadingCircle: {
        color: 'black',
    },
    circleContainer: {
        marginTop: 200,
    },
});



function ValueCard(props){

    const classes = useStyles();

    const [nameMapping, ] = React.useState(props.nameMapping);
    const [valueMapping, ] = React.useState(props.valueMapping);
        

    if(props.value !== "N/A") {
        if(props.isBool)
        {   
            let name = "";
            if (nameMapping !== null) {
                name = nameMapping.hasOwnProperty(props.name) ? nameMapping[props.name] : props.name;
            }
            else{
                name = props.name;
            }

            let message = "";
            let color = "";
            
            if (valueMapping !== null) {
                if(valueMapping.hasOwnProperty(props.name)){
                    if (props.value === true) {
                        if (valueMapping[props.name].hasOwnProperty("true")) {
                            message = valueMapping[props.name]["true"].message;
                            color = valueMapping[props.name]["true"].color.toLowerCase();
                        }
                        else{
                            message = "true";
                            color = "green";
                        }
                    }
                    else if (props.value === false){
                        if (valueMapping[props.name].hasOwnProperty("false")) {
                            message = valueMapping[props.name]["false"].message;
                            color = valueMapping[props.name]["false"].color.toLowerCase();
                        }
                        else{
                            message = "false";
                            color = "yellow";
                        }
                    }
                }
                else{
                    message = "false";
                    color = "yellow";
                }
            }
            else{
                message = "false";
                color = "yellow";
            }
           

            return(
                <div className={classes.ValueCard}>
                    <Card className={classes[`${color}Card`]} raised={true}>
                        <CardContent>
                            <Typography className={classes[`${color}ValueText`]}>
                                <strong>{message}</strong>
                            </Typography>
                            <Typography className={classes[`${color}ValueName`]}>
                                <strong>{name}</strong> 
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            );
        }
        else{
            let name = "";
            if (nameMapping !== null) {
                name = nameMapping.hasOwnProperty(props.name) ? nameMapping[props.name] : props.name;
            }
            else{
                name = props.name;
            }
            return(
                <div className={classes.ValueCard}>
                    <Card className={classes.Card} raised={true}>
                        <CardContent>
                            <Typography className={classes.ValueText}>
                                <strong>{Math.round(props.value*100)/100} {props.unit}</strong>
                            </Typography>
                            <Typography className={classes.ValueName}>
                            <strong>{name}</strong> 
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            );
        }
    }
    else{
        return(
            <div className={classes.ValueCardInvisible}>
                <Card className={classes.Card} raised={true}>
                    <CardContent>
                        <Typography className={classes.ValueText}>
                            <strong>{props.value} {props.unit}</strong>
                        </Typography>
                        <Typography className={classes.ValueName}>
                        <strong>{"N/A"}</strong> 
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withRouter(ValueCard);