
import UserPool from "../../UserPool";

const hasWritePermission = () => {

    const cognitoUser = UserPool.getCurrentUser();

    var hasPermission = false;

    if(cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if(err) {
                console.log(err);
            }
            else if(session.isValid()){
                let userGroups = [];

                userGroups = session.accessToken.payload['cognito:groups'];

                if(userGroups.length > 1 && userGroups.length < 3) {
                    
                    hasPermission =  userGroups.includes("WritePermission");
                }
            }
        })
    }

    return hasPermission;
}

export default hasWritePermission;