const GetElementSpanFromArray = (startIndex, array, elementCount) => {
    
    let returnValue = [];

    for (let index = startIndex; index < (startIndex + elementCount); index++) {
        
        if(index>=0 && index <= array.length){
            returnValue.push(array[index]);
        }
    }

    var filteredReturnValue = returnValue.filter((x) => {
        return x !== undefined;
   });

    return filteredReturnValue;    
};

export default GetElementSpanFromArray;