//React
import React from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

//Axios
import axios from 'axios';

//Material-UI
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress } from '@material-ui/core';

import './index.css';

import { get_ID_Token }  from '../func/cognito/get_Token';
import GetElementSpanFromArray from "../func/GetElementSpanFromArray";

//Components
import { LineChartComponent, PagingDropDown } from ".";
import { Condition }  from ".";

//Recoil
import { useRecoilState } from "recoil";

//Atoms
import { linechartIndexState } from "../recoil/Atoms";

const useStyles = makeStyles((theme) => ({
    LineChartContainer:{
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    LineChartComponent_Container: {
        flex: 1,
    },
    loadingCircle: {
        color: 'black',
    },
    circleContainer: {
        marginTop: 200,
    },
    ButtonContainer_active:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    ButtonContainer_inactive:{
        display: 'flex',
        justifyContent: 'space-between',
        visibility: "collapse"
    },
    Dropdown_active:{
        display: 'flex',
        justifyContent: 'center'
    },
    Dropdown_inactive:{
        display: 'flex',
        justifyContent: 'center',
        visibility: "collapse"
    },
    bSwitchPage: {
        backgroundColor: "#00378b",
        color: "white",
        top: "15%",
    },
}));

const controller = new AbortController();

async function fetchData (unit) {
    const windowLocation = window.location.href;
    const pathname = windowLocation.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    var url = "";

    if(isLocal){
        url = `http://localhost:8080/measurenames/${unit}`;
    }
    else if(isProduction) {
        url = `https://server.mypfeifer.com/measurenames/${unit}`;
    }
    else{
        url = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/measurenames/${unit}`;
    } 

    return await axios.get(url, {
        mehtod: 'GET',
        signal: controller,
        headers: {
            'Authorization': 'Bearer ' + get_ID_Token()
        }
    }).then(res => {
        return res.data;
    });
}

function parseAllowMeasureNames (measureNames, valueMapping) {
    let allowedCharts = [];
    if(measureNames) {
        measureNames.map(element => {
            if(valueMapping !== null){
                if (valueMapping.hasOwnProperty(element)) {
                    if(valueMapping[element].hasOwnProperty("chart")) {
                        if (valueMapping[element].chart === true) {
                            allowedCharts.push(element);
                        }  
                    }
                    else{
                        allowedCharts.push(element);
                    }
                }
                else
                    allowedCharts.push(element);
            }
            else
                allowedCharts.push(element);
            return 0; //Because arrow function expects return value
        }) 
    }

    return allowedCharts.sort();
}

function LineChart_Container_Component(props){

    const classes = useStyles();

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000"); 
    const isProduction = pathname.includes("mypfeifer.com");
    const [isFinished, setIsFinished] = React.useState(false);
    const [measureNames, setMeasureNames] = React.useState([""]);
    const [maxCharts, ] = React.useState(10);
    const [key, setKey] = React.useState();

    const [index, setIndex] = useRecoilState(linechartIndexState);

    useEffect(() => {
        if (index[props.unit] === undefined) {
            setIndex({
                ...index,
                [props.unit]: 0,
            });
        }
        let cancel = false;
        if(isFinished === false) {
            fetchData(props.unit).then(res => {
                if(cancel)
                    return;
                setMeasureNames(parseAllowMeasureNames(res, props.valueMapping));
                setIsFinished(true);
            });
        }

        return () => {
            controller.abort();
            cancel = true;
        }
    }, [props.unit, isFinished, props.valueMapping]);

    let path = "";

    if(isLocal) {
        path = `http://localhost:8080/rangedata/`;
    }
    else if(isProduction) {
        path = `https://server.mypfeifer.com/rangedata/`;
    }
    else {
        path = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/rangedata/`;
    }

    let begin = Math.round(props.timerange[0].getTime() / 1000);
    let end = Math.round(props.timerange[1].getTime() / 1000);

    const bNext_Handler = event => {
        event.preventDefault();
        setIndex({
            ...index,
            [props.unit]: index[props.unit]+maxCharts,
        });
        setKey(Math.random());
    }

    const bLast_Handler = event => {
        event.preventDefault();
        setIndex({
            ...index,
            [props.unit]: index[props.unit]-maxCharts,
        });
        setKey(Math.random());
    }

    const handleChangeDropdown = idx => {
        setIndex({
            ...index,
            [props.unit]: idx,
        });
    }

    if(isFinished) {
        return(
            <div className={classes.LineChartContainer}>
                <div className={measureNames.length > maxCharts ? classes.Dropdown_active : classes.Dropdown_inactive}>
                    <PagingDropDown elements={measureNames} tick={maxCharts} onChange={handleChangeDropdown} active={index[props.unit]}/>
                </div>
                <div className={classes.LineChartComponent_Container} key={key}>
                    {GetElementSpanFromArray(index[props.unit], measureNames, maxCharts).map((measureName) => 
                            <Condition 
                                condition={props.interval !== null && props.interval !== undefined}
                                key={Math.random()}
                                true={
                                    <LineChartComponent
                                        key={Math.random()} 
                                        request={path + props.unit + "/" + measureName + "?begin=" + begin + "&end=" + end + "&interval=" + props.interval} 
                                        measureName={measureName}
                                        nameMapping={props.nameMapping} 
                                    />  
                                }
                                false={
                                    <LineChartComponent 
                                        key={Math.random()}
                                        request={path + props.unit + "/" + measureName + "?begin=" + begin + "&end=" + end} 
                                        measureName={measureName}
                                        nameMapping={props.nameMapping} 
                                    />  
                                }
                            />       
                        )
                    }
                </div>
                <div className={measureNames.length > maxCharts ? classes.ButtonContainer_active : classes.ButtonContainer_inactive}>
                    <Button className={classes.bSwitchPage} variant="contained" disabled={index[props.unit] <= 0} onClick={bLast_Handler}>previous page</Button>
                    <Button className={classes.bSwitchPage} variant="contained" disabled={(index[props.unit]+maxCharts) >= measureNames.length} onClick={bNext_Handler}>next page</Button>
                </div>
            </div>
        ); 
    }
    else {
        return(
            <div>
                <div className={classes.circleContainer}>
                    <CircularProgress className={classes.loadingCircle}/>
                </div>
            </div>
        );
    }
}

export default withRouter(LineChart_Container_Component);