import React from 'react';
import { useHistory } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    About: {
        marginTop: 150,
        display: "flex",
        justifyContent: "center",
        
    },
    Card: {
        flexGrow: 1,
        marginInline: 20,
        maxWidth: 1000,
    },
    Container: {
        display: "flex",
        justifyContent: "center",
    },
    contentContainer: {
        flexGrow: 1,
        marginInline: 20,
        maxWidt: 50,
    },
    content: {
        display: "block",
        textAlign: "start",
    },
    title: {
        color: '#00378b',
    },
    devider: {
        position: "relative",
        border: "none",
        height: 0.5,
        marginBottom: 20,
    },
    footer: {
        position: "absolute",
        bottom: 4,
        height: "2em",
        left: "50%",
        translate: "-50%",
    },
    inhalt: {
        paddingBottom: "2em",
    },
    einrücken: {
        paddingLeft:"2em",
    }
}));

function About(props) {

    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.inhalt}>
            <div className={classes.About}>
                <Card raised={true} className={classes.Card}>
                    <CardHeader title={<Typography className={classes.title} variant="h4">About</Typography>}></CardHeader>
                    <CardContent className={classes.Container}>
                       <div className={classes.contentContainer}>
                           <div className={classes.content}>
                            <Typography variant="h6"> Contact</Typography>
                            <dl className={classes.einrücken}>
                                <dt>PFEIFER Systems GmbH</dt>
                                <dt>Sonnenstrasse 8</dt>
                                <dt>AT-6822 Satteins</dt>
                                <dt>Tel. +43 5524 5419-0</dt>
                                <dt>Fax +43 810 9554 196804</dt>
                                <dt>E-Mail office@pfeifer-systems.at</dt>
                                <dt>Web www.pfeifer-systems.at</dt>
                            </dl>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6"> Information</Typography>
                            <ul>
                                <li>
                                    <Link onClick={() => history.push("/about/LegalInformation")}>Legal Information</Link>
                                </li>
                                <li>
                                    <Link onClick={() => history.push("/about/DataProtection")}>Data Protection</Link>
                                </li>
                            </ul>
                            <hr className={classes.devider}></hr>
                           </div>
                       </div>
                    </CardContent>
                </Card>
            </div>
            <div className={classes.footer}>
                <div>
                    © PEIFER Systems GmbH, Satteins, Austria
                </div>
            </div>
        </div>
    );
}

export default withRouter(About);