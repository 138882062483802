import React, {useEffect, useState} from "react";

import { withRouter } from "react-router-dom";

import "./ParameterForm.css";

import { Card, Checkbox, CircularProgress, Input, TextField, Typography } from '@material-ui/core';
import {Button } from '@material-ui/core';
import axios from 'axios';
import { get_Access_Token, get_ID_Token } from '../../func/cognito/get_Token';


const controller = new AbortController();

const fetcher = async (request, onSuccess, onFailure) => {
    let response = [];

    if(request === undefined) {
        throw new Error('Request data is not existing!');
    }

    response  = await axios.get(request, {
        mehtod: 'GET',
        signal: controller,
        headers: {
            'Authorization': 'Bearer ' + get_ID_Token()
        }
    }).then(res => {
        if(res.data !== null && res.data !== undefined) {
            onSuccess(res.data, res.status);
        }
        else {
            onSuccess(null, res.status);
        }
    }).catch(error => {
        onFailure(error.response.status);
    })
}

const sender = async (request, requestData, onSuccess, onFailure) => {

    if(request === undefined)
        throw new Error('Request URL is not defined!');

    if(requestData === undefined)
        throw new Error("Request data is not defined!");

    await axios.put(request, requestData,{
        methode: 'POST',
        url: request,
        signal: controller,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + get_ID_Token(),
            'Access-Control-Allow-Origin': request,
            'access-token': get_Access_Token()
        }
    })
    .then(res => {
        if(res.data !== null && res.data !== undefined) {
            onSuccess(res.data, res.status);
        }
        else {
            onSuccess(null, res.status);
        }
    })
    .catch(error => {
        onFailure(error.response.status);
    });
}

function ParameterForm(props) {

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000"); 
    const isProduction = pathname.includes("mypfeifer.com");

    var get_path = "";
    var put_path = "";

    if(isLocal) {
        get_path = `http://localhost:8080/parameter/${props.unit}`;
        put_path = `http://localhost:8080/parameter/${props.unit}`;
        //get_path = `https://f5fea10c-14d3-4733-83dc-cf7a48307e78.mock.pstmn.io/parameter/${props.unit}`;
        //put_path = `https://f5fea10c-14d3-4733-83dc-cf7a48307e78.mock.pstmn.io/parameter/${props.unit}`
    }
    else if(isProduction) {
        get_path = `https://server.mypfeifer.com/parameter/${props.unit}`;
        put_path = `https://server.mypfeifer.com/parameter/${props.unit}`;
    }
    else {
        get_path = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/parameter/${props.unit}`;
        put_path = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/parameter/${props.unit}`;
    }

    const[fetch, setFetch] = useState(true);
    const [responseData, setResponseData] = useState();
    const [onFailure, setOnFailure] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [returnValue, setReturnValue] = useState();
    const [failureMessage, setFailureMessage] = useState();
    const [statusCode, setStatusCode] = useState();
    const [postData, setPostData] = useState([]);
    const [key, setKey] = useState(Math.random());

    useEffect(() => {
        let cancel = false;
        if(fetch) {
            try{
                fetcher(get_path, 
                    (data, status) => {
                        if (status === 204) {
                            setStatusCode(status);
                            setFailureMessage("No parameters found for this unit!");
                        }
                        else {
                            setResponseData(data);
                            setFetch(false);
                        }
                    },
                    (error) => {
                        if(error === 403) {
                            console.log("Error-Status: " + error);
                            setStatusCode(error);
                            setFailureMessage("Something went wrong with authorization! Try again later!");
                        }
                        else {
                            console.log("Error-Status: " + error);
                            setStatusCode(error);
                            setFailureMessage("Something went wrong! Try again later!");
                        }
                    }
                );
            }
            catch(e) {
                console.log(e.message);
            }
        }
    
        return () => {
            controller.abort();
            cancel = true;
        }
    }, [fetch, get_path, props.unit]);

    const onInputChange = (name, value, id) => {

        if(typeof value === "boolean"){

            const element = {
                name: name,
                value: value
            }

            const newParameters = responseData.parameters.map((e,i) => {
                if(i === Number.parseInt(id)) {
                    return element;
                }
                else {
                    return e;
                }
            });

            setResponseData({
                ...responseData,
                parameters: newParameters
            });
            setKey(Math.random());
        }

        if(typeof postData !== "undefined") {
            const index = postData.findIndex(x => x.name === name);

            if(index === -1 ) {
                postData.push(
                    {
                        name: name,
                        value: value
                    }
                );
            }
            else {
                const nextElements = postData.map((element, i) => {
                    if (i === index) {
                        element.value = value;
                        return element;
                    }
                    else {
                        return element;
                    }
                })
            }
        }
        else {
            setPostData([{
                name: name,
                value: value
            }]);
        } 

        setErrMessage("");
        setSuccessMessage("");
    };

    const onUpdate = (event) => {
        event.preventDefault();

        const updateData = {
            unitID: responseData.unitID,
            parameters: postData
        };

        console.log(updateData);

        if(updateData.parameters.length > 0) {
            sender(put_path, updateData, (data, status) => {
                if (status === 204) {
                    setErrMessage("Could not perform update! Pleasy try again later.");
                    setOnFailure(true);
                }
                else {
                    setSuccessMessage("Changed paramters successfully");
                    setOnFailure(false);
                    setResponseData(data);
                }
            }, (error) => {
                console.log("Error-Status: " + error);
                if (error === 403) {
                    setErrMessage("You don't have the permission to update parameters!");
                    setOnFailure(true);
                }
                else {
                    setErrMessage("Something went wrong! Please try again later.");
                    setOnFailure(true);
                }
            })
        }
        else {
            setErrMessage("Nothing to submit!");
            setOnFailure(true);
        }
        setPostData([]);
    };

    const getType = (parameter) => {
        if (typeof parameter.value === "number") {
            return( 
                <Input
                    className={"paramterValue"} 
                    id={responseData.parameters.findIndex(x => x.name === parameter.name).toString()}
                    label={parameter.name}
                    name={parameter.name}
                    onChange={(event) => {
                        onInputChange(event.target.name, Number.parseInt(event.target.value), event.target.id)
                    }}
                    defaultValue={parameter.value}
                    variant="filled"
                    type="number"
                />
            );
        }
        else if (typeof parameter.value === "boolean") {
            return (
                <Checkbox 
                    key={key}
                    className={"checkbox"}
                    id={responseData.parameters.findIndex(x => x.name === parameter.name).toString()}
                    label={parameter.name}
                    name={parameter.name}
                    onChange={(event) => {
                        onInputChange(event.target.name, event.target.checked, event.target.id)
                    }}
                    checked={parameter.value}
                />
            )
        }
        else {
            return( 
                <Input
                    className={"paramterValue"} 
                    id={responseData.parameters.findIndex(x => x.name === parameter.name).toString()}
                    label={parameter.name}
                    name={parameter.name}
                    onChange={(event) => {
                        onInputChange(event.target.name, event.target.value, event.target.id)
                    }}
                    defaultValue={parameter.value}
                    variant="filled"
                    type="standard"
                />
            );
        }
    }
    
    useEffect(() => {
        if(typeof failureMessage !== "undefined") {
            if(statusCode === 204) {
                setReturnValue(
                    <div className="circleContainer">
                        <Typography className={"successMessage"} >{failureMessage}</Typography>
                    </div>
                );
            }
            else {
                setReturnValue(
                    <div className="circleContainer">
                        <Typography className={"errMessage"} >{failureMessage}</Typography>
                    </div>
                );
            }
        }
        else if((typeof responseData === "undefined") || (responseData === null)) {
            setReturnValue(
                <div className="circleContainer">
                    <CircularProgress className="loadingCircle"/>
                </div>
            );
        }
        else {
            setReturnValue(
                <form onSubmit={onUpdate} className={"formContainer"}>
                    <Typography variant="h3" className={"title"}>Parameter</Typography>
                    <div className={"subtopic-container"}>
                        <Typography variant="h6">Change Parameter of {props.deviceName}:</Typography>
                    </div>
                    {
                        responseData.parameters.map(parameter => 
                            <div className={"parameterContainer"} key={parameter.name}>
                                <div className={"parameterName"}>{parameter.name.toUpperCase()}</div>
                                <div className={"parameterValue"}>{getType(parameter)}</div>
                            </div>
                        )
                    }
                    {onFailure
                        ?<div >
                            <Typography className={"errMessage"} >{errMessage}</Typography>
                        </div>
                        :<div >
                            <Typography className={"successMessage"} >{successMessage}</Typography>
                        </div>
                    }
                    <Button 
                        variant="contained"
                        type="submit" 
                        disableRipple={true}
                        className="Button MuiButton-fullWidth MuiButton-root MuiButtonBase-root MuiButton-contained"
                        fullWidth={true}
                    >
                        Submit
                    </Button>
                </form>
            );
        }
    }, [responseData, failureMessage, errMessage, successMessage]);

    
    return (
        <Card raised={true} className={"Card"}>
            {returnValue}
        </Card>
    );
}

export default withRouter(ParameterForm);