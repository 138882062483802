//React imports
import React from 'react';
import clsx from 'clsx';
import { withRouter, useHistory } from "react-router-dom";

//Material-UI imports
import Link from '@material-ui/core/Link';
import { makeStyles,  useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PfeiferLogo from './Pfeifer_Holding.png'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

//Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import StorageIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import ParameterSettings from '@material-ui/icons/Tune';

//Other imports
import UserPool from "../UserPool";
import { Typography } from '@material-ui/core';
import getUserGroup from '../func/cognito/getUserGroup';
import getUserName from '../func/cognito/getUserName';
import Condition from './Condition';

import hasWritePermission from "../func/cognito/hasWritePermission";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    AppBarColor:{
        backgroundColor: '#FFFFFF',
    },
    AccountCircleColor: {
        color: '#00378b',
    },
    sideBarIcon: {
        color: '#00378b',
    },
    grow: {
      flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        color: '#00378b',
        fontWeight: 'Bold',
      },
    logoBig: {
        maxWidth: 250,
        flexGrow: 1,
    },
    logoMedium: {
        maxWidth: 200,
        justifyContent: 'center',
    },
    logoSmall: {
        marginLeft: 10,
        maxWidth: 170,
    },
    link: {
        display: 'flex',
        color: '#00378b',
    },
    listItemLinkColor: {
        color: '#00378b',
    },
    Icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
        color: '#00378b',
    },
    accountCircle: {
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    AccountName: {
        //color: 'black',
        color: '#00378b',
        marginInline: 5,
    },
    AccountContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    } 
})); 

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function Navigation(props){

    const classes = useStyles();

    const desktopAppBar = useMediaQuery('(min-width:850px)');
    const titleSize = useMediaQuery('(min-Width:350px)');

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const history = useHistory();
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        const currentUser = UserPool.getCurrentUser();

        if(currentUser !== null){
                currentUser.signOut();
                console.log("LoggedOut");
        }
        else{
            console.log("User already loggedOut!");
        }
        window.location.assign("/login");
    };

    return(
        <div className="Navigation">
            {desktopAppBar
            ? <AppBar position="static" className={classes.AppBarColor}>
                <Toolbar className={classes.toolbar} >
                    <a href="/">
                        <img src={PfeiferLogo} alt="Logo" className={classes.logoBig} />
                    </a>
                    
                    <Breadcrumbs separator="|" component="nav" aria-label="breadcrumb" >
                        {/* <Link color="primary" component="a" onClick={() => {history.push("/"); props.onClick(e);}} className={classes.link} >
                            <HomeIcon className={classes.Icon}/>
                            Home
                        </Link> */} 
                        <Link color="primary" component="a" onClick={() => {history.push("/");}} className={classes.link} >
                            <StorageIcon className={classes.Icon}/>
                            Dashboard
                        </Link>
                        {
                            hasWritePermission()
                            ?<Link color="primary" component="a" onClick={() => {history.push("/parameter");}} className={classes.link} >
                                <ParameterSettings className={classes.Icon}/>
                                Parameter
                            </Link>
                            : null
                        }
                        <Link color="primary" component="a" onClick={() => {history.push("/log");}} className={classes.link} >
                            <DescriptionIcon className={classes.Icon}/>
                            Log
                        </Link>
                        <Link color="primary" onClick={() => {history.push("/about");}} className={classes.link}>
                            <InfoIcon className={classes.Icon}/>
                            About
                        </Link>
                    </Breadcrumbs>
                    <div className={classes.AccountContainer}>
                        <Typography className={classes.AccountName}>{getUserGroup()} / {getUserName()}</Typography>
                        <Tooltip title="Settings | Logout">
                            <IconButton className={classes.accountCircle} size="medium" onClick={handleClick}>
                                <AccountCircle fontSize="large" className={classes.AccountCircleColor}></AccountCircle>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                        <Link component="a" onClick={() => {history.push("/settings");}} className={classes.link}>Settings</Link>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                    
                </Toolbar>
            </AppBar>
        : <AppBar position="static" className={classes.AppBarColor}>
            <Toolbar className={classes.toolbar} > 
                <IconButton 
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide, classes.sideBarIcon)}
                    size="small"
                >
                    <MenuIcon fontSize="large"/>
                </IconButton>

                {/* {logoSize
                ?<a href="/">
                    <img src={PfeiferLogo} alt="Logo" className={classes.logoMedium} />
                </a>
                :<a href="/">
                    <img src={PfeiferLogo} alt="Logo" className={classes.logoSmall} />
                </a>} */}
                
                {titleSize
                ?<a href='/'>
                    <img src={PfeiferLogo} alt="Logo" className={classes.logoMedium} />
                </a>
                :<a href='/'>
                    <img src={PfeiferLogo} alt="Logo" className={classes.logoSmall} />
                </a>}
                <Tooltip title="Settings | Logout">
                    <IconButton size="small" onClick={handleClick}>
                        <AccountCircle fontSize="large" className={classes.AccountCircleColor} ></AccountCircle>
                    </IconButton>
                </Tooltip>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <Link component="a" onClick={() => {history.push("/settings"); props.onClick();}} className={classes.link}>Settings</Link>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>}

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          
          <Divider />
          
            <List>
                {/* <a onClick={() => {history.push("/"); props.onClick();}}>
                    <img src={PfeiferLogo} alt="Logo" className={classes.logoMedium} />
                </a>
                <Divider/> */}
                <Typography className={classes.AccountName}>{getUserGroup()}/{getUserName()}</Typography>
                <Divider/>
                {/* <ListItemLink href="/">
                    <ListItemIcon>
                        <HomeIcon className={classes.listItemLinkColor}/>
                    </ListItemIcon>
                    <ListItemText className={classes.listItemLinkColor} primary="Home" />
                </ListItemLink>
                <Divider/> */}
                <ListItemLink onClick={() => {history.push("/"); props.onClick();}}>
                    <ListItemIcon>
                        <StorageIcon className={classes.listItemLinkColor}/>  
                    </ListItemIcon>
                    <ListItemText className={classes.listItemLinkColor} primary="Dashboard" />
                </ListItemLink>
                <Divider/>
                <ListItemLink onClick={() => history.push("/log")}>
                    <ListItemIcon>
                        <DescriptionIcon className={classes.listItemLinkColor}/>
                    </ListItemIcon>
                    <ListItemText className={classes.listItemLinkColor} primary="Log" />
                </ListItemLink>
                <Divider/>
                <ListItemLink onClick={() => {history.push("/about"); props.onClick();}}>
                    <ListItemIcon>
                        <InfoIcon className={classes.listItemLinkColor}/>
                    </ListItemIcon>
                    <ListItemText className={classes.listItemLinkColor} primary="About" />
                </ListItemLink>
            </List>
        </Drawer>
        </div>
    );
}

export default withRouter(Navigation);