import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import UserPool from '../../UserPool';

const ChangePhoneNumber = (newPhoneNumber) => {

    const attributeList = [];
    const attribute = {
        Name: 'phone_number',
        Value: newPhoneNumber,
    };

    const userAttribute = new CognitoUserAttribute(attribute);

    attributeList.push(userAttribute);

    const user = UserPool.getCurrentUser();

    if(user != null) {
        user.getSession((err, result) => {
            if(err) {
                alert(err);
                return;
            }
        });
   

        user.updateAttributes(attributeList, (err, result) => {
            if(err) {
                alert(err);
                window.location.assign("/login");
                return;
            }
            else{
                console.log("Change phone number", result);
            }
        });
    }
};

export default ChangePhoneNumber;