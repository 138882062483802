import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import UserPool from '../../UserPool';

const ChangeEmail = (newEmail) => {

    const attributeList = [];
    const attribute = {
        Name: 'email',
        Value: newEmail,
    };

    const userAttribute = new CognitoUserAttribute(attribute);

    attributeList.push(userAttribute);

    const user = UserPool.getCurrentUser();

    if(user != null) {
        user.getSession((err, result) => {
            if(err) {
                alert(err);
                console.log("getSession", err);
                return;
            }
        });
    

        user.updateAttributes(attributeList, (err, result) => {
            if(err) {
                alert(err);
                window.location.assign("/login");
                return;
            }
            else{
                console.log("Change email", result);
            }
        });

    }
};

export default ChangeEmail;