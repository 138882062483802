//React imports
import React from 'react';
import { useEffect } from 'react';
import { withRouter } from "react-router-dom";

//Material-UI imports
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

//axios
import axios from 'axios';

//Actions
import Timestamp_to_Time_Conversion from '../func/Timestamp_to_Time_Conversion';
import { get_ID_Token }  from '../func/cognito/get_Token';


const useStyles = makeStyles({
    log: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '97%',
    },
    LogContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: 30,
        minWidth: '50vw',
        marginTop: 10,
    },
    header: {
        backgroundColor: '#00378b',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 1050,
        flexGrow: 1,
        color: 'white',
    },
    title: {
        color: 'white',
    },
    SubTitle: {
        color: 'white',
    },
    ValueName: {
      color: '#00378b',
    },
    Card:{
        padding: 30,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
        minHeight: 100,
        width: '100%',
    },
    TreeView: {
        display: 'flex',
        maxHeight: 200,
        flexGrow: 1,
        maxWidth: 200,
        alignItems: 'center',
        marginRight: 20,
    },
    Icon: {
        color: '#00378b',
    },
    listitem: {
        height: 25,
        color: '#00378b',
    },
    link: {
        color: "#00378b",
        fontSize: 17,
        display: 'block',
        width: 100,
        textAlign: 'center',
    },
    loadingCircle: {
        color: 'white',
    },
    CardHeader: {
        marginTop: -25,
        color: "#00378b",
    },
    circleContainer: {
        marginTop: 100,
        marginBottom: 100,
    },
    selectionMessage: {
        color: '#00378b',
    },
    rangeSelection: {
        marginRight: 30,
    },
    rangeController: {
        minWidth: 120,
    },
    Message: {
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        maxHeight: 18,
    },
    messages: {
        minWidth: '97%',
    },
    emptyLog: {
        //color: '#d32f2f',
        color: '#00378b',
    }
});

async function fetcher (unit, timerange, onSuccess) {
    const windowLocation = window.location.href;
    const pathname = windowLocation.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    let begin = Math.round(timerange[0].getTime() / 1000);
    let end = Math.round(timerange[1].getTime() / 1000);

    let url = "";

    if(isLocal){
        url = `http://localhost:8080/messages/${unit}?begin=${begin}&end=${end}`;
    }
    else if (isProduction) {
        url = `https://server.mypfeifer.com/messages/${unit}?begin=${begin}&end=${end}`;
    }
    else{
        url = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/messages/${unit}?begin=${begin}&end=${end}`;
    } 

    let response = await axios.get(url, {
        mehtod: 'GET',
        headers: {
            'Authorization': 'Bearer ' + get_ID_Token()
        }
    }).then(res => {
        return res.data;
    });

    if(response !== null && response !== undefined && response.length !== 0) {
        onSuccess(true, response);
    }
    else {
        onSuccess(false, null);
    }
}

function parseAllowedMessages(data=null, valueMapping) {
    let allowedMessages = [];

    //console.log("MessageData: " + messageData);

    if(data !== null) {
        //console.log(data);
        const keys = Object.keys(valueMapping);
        data.map(element => {
            if(keys.includes(element.name)) {
                allowedMessages.push(element);
            }
            return 0; //Because arrow functions expects return value
        })
        //console.log(allowedMessages);
        return allowedMessages;
    }

    return null;
}

const getMappingEntry = (measureName, value, valueMapping, key) => {
    if (valueMapping !== null) {
        if (valueMapping.hasOwnProperty(measureName)) {
            if (value === "true") {
                if (valueMapping[measureName].hasOwnProperty("true")) {
                    return valueMapping[measureName]["true"][key];
                }
                else{
                    return "warning";
                }    
            }
            else{
                if (valueMapping[measureName].hasOwnProperty("false")) {
                    return valueMapping[measureName]["false"][key];
                }
                else {
                    return "success";
                }    
            }
        }
        else{
            if (value === "true") {
                return "warning";
            }
            else {
                return "success"; 
            }
        }
    }
    else{
        if (value === "true") {
            return "warning";
        }
        else {
            return "success"; 
        }
    }
}

  
function LogComponent(props) {

    const classes = useStyles();

    const [responseData, setResponseData] = React.useState([]);
    const [isFinished, setIsFinished] = React.useState(false);

    useEffect(() => {
        try{
            fetcher(props.unit.id, props.timerange, (success, data) => {
                if(success){
                    setResponseData(parseAllowedMessages(Timestamp_to_Time_Conversion(data), props.valueMapping));
                    setIsFinished(true);
                }
                else {
                    setIsFinished(true);
                }
            });
        }
        catch(e) {
            console.log(e.message);
        }
    }, [props.unit, props.timerange, props.valueMapping]);

    const renderMessages = (dataPoints, valueMapping) => {

        let dataArray = Array.from(dataPoints);

        let messages = null;

        if(dataArray.length === 0) {
            messages = (
                <p className={classes.emptyLog}>
                    No log entries found for this device and time range!
                </p>
            );
        }
        else {
            messages = dataArray.map((dataPoint) => 
                <Alert 
                    severity={getMappingEntry(dataPoint.name, dataPoint.value, valueMapping, "severity")} 
                    className={classes.Message}>
                    <strong>{dataPoint.timestamp}  
                        {" - "}  {getMappingEntry(dataPoint.name, dataPoint.value, valueMapping, "message")}
                    </strong>
                </Alert>  
            );
        }
        return(
            <div >
                <Typography className={classes.Messages}>{messages}</Typography>
            </div>
        );
    };

    if(isFinished === false) {
        return (
            <div className={classes.log}>
                <div className={classes.circleContainer}>
                    <CircularProgress className={classes.loadingCircle}/>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={classes.log}>
                <Typography className={classes.messages}>
                    {renderMessages(responseData, props.valueMapping)}
                </Typography>
            </div>
        );
    }   
}

export default withRouter(LogComponent);