import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import { withRouter } from 'react-router';
import { CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { TextField, Button, FormControl } from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';

const useStyles = makeStyles((theme) => ({
    LoginPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
    },
    LoginFormContainer: {
        display: 'flex',
        maxWidth: 400,
        flexShrink: 1,
        maxHeight: 600,
        justifyContent: 'center',
        alignItems: 'center',
    },
    CardHeader: {
        padding: 30,
        display: 'block',
    },
    TextField: {
        display: 'flex',
        minWidth: 300,
        flexShrink: 1,
        marginBottom: 30,
    },
    Button: {
        display: 'flex',
        height: 45,
        marginTop: 50,
        marginBottom: 20,
        backgroundColor: '#00378b',
        color: 'white',
        borderRadius: '%',
    },
    title: {
        flexGrow: 1,
        color: '#00378b',
        fontWeight: 'Bold',
      },
    subtitle: {
        marginTop: 20,
    },
    successMessage: {
        color: '#249225',
    },
    errMessage: {
        color: '#ff2400',
    },
    backButtonContainer: {
        display: 'flex',
        marginTop: 20,
        marginLeft: 30,
    },
    backButton: {
        color: '#00378b',
        stroke: '#00378b',
    },
    footer: {
        position: "absolute",
        bottom: 8,
        left: "50%",
        translate: "-50%",
    }
}));

function ForgotPassword() {

    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [onConfirmation, setOnConfirmation] = useState(false);
    const [failure, setFailure] = useState(false);
    const [redirectTime, setRedirectTime] = useState("");

    let time = 5;

    const handleForgotPassword = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        });

        if(confirmPassword === newPassword) {
            setFailure(false);
            user.forgotPassword({
                onSuccess: (result) => {
                    console.log("success", result);
                    setOnConfirmation(false);
                },
                onFailure: (err) => {
                    console.log("failure", err)
                    setOnConfirmation(false);
                    setFailure(true);
                    setMessage(err.message);
                },
                inputVerificationCode: (data) => {
                    console.log("input verification code",data);
                    setOnConfirmation(true);
                },
            });
        }
        else{
            setOnConfirmation(false);
            setFailure(true);
            setMessage("Passwords do not match!");
        }
    };

    const handleConfirmation = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        });

        user.confirmPassword(confirmationCode, newPassword, {
            onSuccess: (success) => {
                console.log(`Success: ${success}`);
                setFailure(false);
                setMessage("Password Reset Succeded");
                for(time = 10; time < 0; time-=1) {
                    setRedirectTime(`You will be redirected in ${time} seconds `);
                    setTimeout(() => {}, 1000);
                }
                window.location.assign("/login");                      
            },
            onFailure: (err) => {
                console.log(`Error: ${err}`);
                setFailure(true);
                setMessage(err.message);
            },
        });
    };

    
    

    return(
        <div>
            <div className={classes.backButtonContainer}><Button className={classes.backButton} variant="outlined" href="/login" startIcon={<ArrowBackIosIcon/>}>Login</Button></div>
            <div className={classes.LoginPage}>
                <Card raised={true} className={classes.LoginFormContainer}>
                   <div className="FormContainer">
            
                    <CardHeader
                            title={
                                <Typography variant="h4" className={classes.title}>
                                    myPFEIFER
                                </Typography>}
                            subheader={
                                onConfirmation
                                ?<Typography className={classes.subtitle} variant="h3">
                                    Type Varification Code
                                </Typography>:
                                <Typography className={classes.subtitle} variant="h3">
                                    Reset Password
                                </Typography>}
                            className={classes.CardHeader}
                        />
                        <CardContent>
                            {onConfirmation
                                ?<form onSubmit={handleConfirmation}>
                                    <FormControl>
                                        <div>
                                            <TextField
                                                type="text" id="VerficationCodeInput" required={true} className={classes.TextField} label="verification code"
                                                value={confirmationCode}
                                                onChange={(e) => setConfirmationCode(e.target.value)} />
                                        </div>
                                        {failure
                                            ?<div >
                                                <Typography className={classes.errMessage} >{message}</Typography>
                                            </div>
                                            :<div >
                                                <Typography className={classes.successMessage} >{message}</Typography>
                                            </div>
                                        }
                                        <div>
                                            <Typography>{redirectTime}</Typography>
                                        </div>
                                        <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                            Login
                                        </Button>
                                    </FormControl>
                                </form>
                                :<form onSubmit={handleForgotPassword}>
                                    <FormControl>
                                        <div>
                                            <TextField
                                                type="text" id="UsernameInput" required={true} className={classes.TextField} label="username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)} />
                                        </div>
                                        <div>
                                            <TextField
                                                type="password" id="newPasswordInput" required={true} className={classes.TextField} label="new password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)} />
                                        </div>
                                        <div>
                                            <TextField
                                                type="password" id="confirmPasswordInput" required={true} className={classes.TextField} label="confirm new password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)} />
                                        </div>
                                        {failure
                                            ?<div >
                                                <Typography className={classes.errMessage} >{message}</Typography>
                                            </div>
                                            :<div >
                                                <Typography className={classes.successMessage} >{message}</Typography>
                                            </div>
                                        }
                                        <Button type="submit" disableRipple={true} className={classes.Button} fullWidth={true} variant="contained">
                                            Reset Password
                                        </Button>
                                    </FormControl>
                                </form>
                            }
                        </CardContent>
                   </div>
                </Card>
            </div>
            <div className={classes.footer}>
                <div>
                    © PEIFER Systems GmbH, Satteins, Austria
                </div>
            </div>
        </div>
    );
}

export default withRouter(ForgotPassword);
