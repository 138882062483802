//UserPool
import UserPool from '../../UserPool';

const getUserSub = () => {
    const cognitoUser = UserPool.getCurrentUser();

    let sub = "";

    if(cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if(err) {
                console.log(err);
            }
            else if(session.isValid()) {

                sub = session.idToken.payload.sub;

            }
        })
    }
    return sub;
};

export default getUserSub;