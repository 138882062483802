import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import { CircularProgress } from "@material-ui/core";

import ValueSheet from "./ValueSheet";

import getUserGroup from "../func/cognito/getUserGroup";
import { get_ID_Token } from "../func/cognito/get_Token";

import { Typography } from "@material-ui/core";

import axios from "axios";

//SWR
import { SWRConfig } from 'swr';

const useStyles = makeStyles({
    loadingCircle: {
        color: 'black',
    },
    circleContainer: {
        marginTop: 200,
    },
    selectionContainer_nowrap: {
        display: 'flex',
        marginTop: 20,
        justifyContent: "space-around",
        marginBottom: 20,
    },
    unitInformation: {
        padding: 15,
    },
    informationCard: {

    },
    fontColor: {
        color: "#00378b",
    },
});

const controller = new AbortController();

async function fetchData (group, device, location) {
    const windowLocation = window.location.href;
    const pathname = windowLocation.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    var url = "";
    var url_namemapping = "";
    var url_valuemapping = "";

    if(isLocal){
        url = `http://localhost:8080/measurenames/location/${group}/${device}/${location}`;
        url_namemapping = `http://localhost:8080/nameMapping/location/${group}/${location}`;
        url_valuemapping = `http://localhost:8080/valueMapping/location/${group}/${location}`;
    }
    else if(isProduction) {
        url = `https://server.mypfeifer.com/measurenames/location/${group}/${device}/${location}`;
        url_namemapping = `https://server.mypfeifer.com/nameMapping/location/${group}/${location}`;
        url_valuemapping = `https://server.mypfeifer.com/valueMapping/location/${group}/${location}`;
    }
    else{
        url = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/measurenames/location/${group}/${device}/${location}`;
        url_namemapping = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/nameMapping/location/${group}/${location}`;
        url_valuemapping = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/valueMapping/location/${group}/${location}`;
    } 

    return {
        measureNames: await axios.get(url, {
            mehtod: 'GET',
            signal: controller,
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        }),
        nameMapping: await axios.get(url_namemapping, {
            mehtod: 'GET',
            signal: controller,
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        }),
        valueMapping: await axios.get(url_valuemapping, {
            mehtod: 'GET',
            signal: controller,
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        })
    };
}

function ValueSheetContainer (props) {
    const classes = useStyles();
    const [isFinished, setIsFinished] = React.useState(false);
    const [responseData, setResponseData] = React.useState(null);
    const [key, setKey] = React.useState(Date.now());

    useEffect(() => {
        let cancel = false;
        if(isFinished === false) {
            fetchData(getUserGroup(), props.device, props.locationName).then(res => {
                if (cancel)               
                    return;
                setResponseData(res);
                setIsFinished(true);
                setKey(Date.now());
            });
        }
        else {
            setKey(Date.now());
        }

        return() => {
            controller.abort();
            cancel = true;
        }
    }, [responseData, props.device, props.locationName, isFinished]);

    if(isFinished && responseData != null){
        return(
            <div className="ValueSheetContainer">
                <div className={classes.selectionContainer_nowrap}>
                    <Card raised={true} className={classes.informationCard}>
                        <Typography className={classes.unitInformation}>
                            <strong className={classes.fontColor}>Selected Location: </strong> {props.locationName}
                        </Typography>
                    </Card>
                </div>
                <div>
                    {props.units.map(unit => (
                        <SWRConfig key={Math.random()} value={{refreshInterval: 5000,}}>
                            <ValueSheet 
                                key={key} 
                                unit={unit.id}         
                                unitName={unit.name} 
                                device={props.device} 
                                measureNames={responseData.measureNames} 
                                nameMapping={responseData.nameMapping}
                                valueMapping={responseData.valueMapping}
                            />
                        </SWRConfig>
                    ))}
                </div>
            </div>
        );
    }
    else{
        return(
            <div>
                <div className={classes.circleContainer}>
                    <CircularProgress className={classes.loadingCircle}/>
                </div>
            </div>
        );
    }
}

export default withRouter(ValueSheetContainer);