//React
import React from "react";
import { withRouter } from "react-router-dom";

//Material-UI
import { makeStyles } from "@material-ui/styles";
import { FormControl } from "@material-ui/core";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import Enumerable from "linq";


const useStyles = makeStyles((theme) => ({
    rangeController: {
        minWidth: 120,
    },
    container: {
        display: "flex",
        alignItems: 'center',
    }
}));



//LineChart Component-Function
function UserDataDropdown(props){

    const classes = useStyles();

    const [entries, setEntries] = React.useState([]);

    React.useEffect(() => {
        setEntries(props.userData);
    }, [props.userData]);

    const handleSelection = (event) => {
        event.preventDefault();
        var condition = event.target.value.toString().split(" > ")[1];
        var result = Enumerable.from(entries).where(entry => entry.name === condition).toArray()[0];
        props.handleSelect(result);
    };

    return(
        <div className={classes.container}>
            <FormControl className={classes.rangeController}>
                <InputLabel id="Device-Selection-label">Device Data</InputLabel>
                <Select
                    labelId="Device-Selection-label"
                    id="select-Device"
                    value={props.value}
                    label="Device"
                    onChange={handleSelection}
                >
                    {
                        entries.map(entry => (
                            <MenuItem key={Math.random()} value={entry.device + ' > ' + entry.name}>
                                {entry.device + ' > ' + entry.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    );
}

export default withRouter(UserDataDropdown);