import UserPool from '../../UserPool';

const ChangePassword = (oldPassword, newPassword) => {

    const currentUser = UserPool.getCurrentUser();

    if(currentUser != null) {
        currentUser.getSession((err, result) => {
            if(err) {
                alert(err);
                return;
            }   
        });
    
        currentUser.changePassword(oldPassword, newPassword, (err, result) => {
            if(err) {
                alert(err);
                window.location.assign("/login");
            }
            else{
                console.log(`Password change successful: ${result}`);
            }
        });
    }
};
export default ChangePassword;