import React from "react";
import { withRouter } from "react-router";


class Clock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            time: new Date().toLocaleString()
        };
    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000 
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.setState({
            time: new Date().toLocaleString()
        });
    }

    render() {
        return(
            <div>
                <strong style={{color:'white'}}>{this.state.time}</strong>
            </div>
        )
    }
};

export default withRouter(Clock);