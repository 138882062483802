//UserPool
import UserPool from '../../UserPool';

const getUserGroup = () => {
    const cognitoUser = UserPool.getCurrentUser();

    let group = "";

    if(cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if(err) {
                console.log(err);
            }
            else if(session.isValid()) {
                let userGroups = [];

                userGroups = session.accessToken.payload['cognito:groups'];

                if(userGroups.length > 1 && userGroups.length < 3) {
                    const filtered = userGroups.filter((e) => {
                        return e !== 'WritePermission'
                    });

                    group = filtered[0];
                }
                else {
                    group = userGroups[0];
                }
            }
        })
    }
    return group;
};

export default getUserGroup;