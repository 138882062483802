
function ParseToInteger(data) {
    for(let i = 0; i<data.length; i++){
        if(Object.keys(data[i]).includes("value")) {
            data[i].value = parseInt(data[i].value);
        } 
    }
    return data;
};

export default ParseToInteger;