import React from 'react';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    About: {
        marginTop: 150,
        display: "flex",
        justifyContent: "center",
        
    },
    Card: {
        flexGrow: 1,
        marginInline: 20,
        maxWidth: 1000,
    },
    Container: {
        display: "flex",
        justifyContent: "center",
    },
    contentContainer: {
        flexGrow: 1,
        marginInline: 20,
        maxWidt: 50,
    },
    content: {
        display: "block",
        textAlign: "start",
    },
    title: {
        color: '#00378b',
    },
    devider: {
        position: "relative",
        border: "none",
        height: 0.5,
        marginBottom: 20,
    },
    footer: {
        position: "absolute",
        bottom: 4,
        height: "2em",
        left: "50%",
        translate: "-50%",
    },
    inhalt: {
        paddingBottom: "2em",
    },
    einrücken: {
        paddingLeft:"2em",
    }
}));

function DataProtection(props) {

    const classes = useStyles();

    return (
        <div className={classes.inhalt}>
        <div className={classes.About}>
            <Card raised={true} className={classes.Card}>
                <CardHeader title={<Typography className={classes.title} variant="h4">Data protection</Typography>} 
                    subheader={<Typography className={classes.subtitle}>Data protection declaration</Typography>}/>
                <CardContent className={classes.Container}>
                   <div className={classes.contentContainer}>
                       <div className={classes.content}>
                            <Typography variant="h6">Introduction</Typography>
                                <p className={classes.einrücken}>
                                    As the responsible body we, PFEIFER Systems GmbH, hereby inform you below of what personal data are processed when you use our website 
                                    (https://systems.pfeifer.info) and for what purposes. 
                                    This data protection declaration is based on the European General Data Protection Regulation (EGDPR), 
                                    the Bundesdatenschutzgesetz (BDSG neu) [German Federal Data Protection Act, new edition] and the Telemediengesetz (TMG) 
                                    [Telemedia Act] of the Federal Republic of Germany.
                                </p>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6"> Name and address of the responsible person within the meaning of Article 4 EGDPR</Typography>
                                <dl className={classes.einrücken}>
                                    <dt>PFEIFER Systems GmbH</dt>
                                    <dt>Sonnenstrasse 8</dt>
                                    <dt>AT-6822 Satteins</dt>
                                    <dt>Managing Directors Dr. Martin Jenni, Christian Schlögl</dt>
                                    <br/>
                                    <dt>Tel. +43 5524 5419-0</dt>
                                    <dt>Fax +43 810 9554 196804</dt>
                                    <dt>E-Mail office@pfeifer-systems.at</dt>
                                    <br/>
                                    <dt>Contact Data Protection: mjenni@pfeifer-systems.at</dt>
                                </dl>
                                
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6">Encryption</Typography>
                                <p className={classes.einrücken}>
                                    We use state-of-the-art encryption techniques such as SSL via HTTPS to protect the security of your data during transmission.
                                </p>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6">Contact</Typography>
                                <p className={classes.einrücken}>
                                    Our website enables you to contact us by e-mail or contact forms. The information that you provide will be processed for the purpose of 
                                    handling the respective enquiry as well as for possible follow-up questions in accordance with Article 6 paragraph 1 letter b) EGDPR. 
                                    Forwarding of this information to third parties will not take place, with the exception of other companies of the PFEIFER Group, if 
                                    necessary.
                                </p>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6">Contact</Typography>
                                <p className={classes.einrücken}>
                                    Our website enables you to contact us by e-mail or contact forms. The information that you provide will be processed for the purpose of 
                                    handling the respective enquiry as well as for possible follow-up questions in accordance with Article 6 paragraph 1 letter b) EGDPR. 
                                    Forwarding of this information to third parties will not take place, with the exception of other companies of the PFEIFER Group, if 
                                    necessary.
                                </p>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6">Use of Amazon AWS and other services</Typography>
                                <p className={classes.einrücken}>
                                    In order to run this webapplication we use the Amazon Web Services (AWS) and other JavaScript libraries. The calling of this libraries or the usage
                                    of AWS automatically initiates a connection to the operator of these services. Its thereby possible that the operators of corresponding libraries or services will collect
                                    and analyse data about these calls. For authentication in this application we use the Amazon service "AWS Cognito", therefore the user data (e.g. email address, username, 
                                    password, phone number) is getting stored in the AWS-Cloud.
                                </p>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6">Reservation of changes</Typography>
                                <p className={classes.einrücken}>
                                    We reserve the right to make changes to the data protection declaration in future, to continuously 
                                    adapt it to our website and to changing legal requirements.
                                </p>
                            <hr className={classes.devider}></hr>
                            <Typography variant="h6">Your rights to information, correction, blocking, revocation and deletion </Typography>
                                <p className={classes.einrücken}>
                                    You have the right at any time to receive information regarding the personal data by us. You are also entitled to demand the correction, 
                                    blocking or deletion of the data unless this is contrary to any statutory storage stipulations. If necessary, please contact our Data Protection 
                                    Officer regarding this. If you actively revoke the receipt of the newsletter, we have to keep your e-mail address in a black list in order 
                                    to be able to comply with your revocation.
                                </p>
                            <hr className={classes.devider}></hr>
                       </div>
                   </div>
                </CardContent>
            </Card>
        
        </div>
    </div>
    );

}

export default withRouter(DataProtection);