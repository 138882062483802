//React
import React from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

//Axios
import axios from 'axios';

//Material-UI
import { makeStyles } from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import { Typography} from "@material-ui/core";
import { Button } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionDetails } from "@material-ui/core";
import CalendarIcon from '@material-ui/icons/DateRange';
import { CircularProgress } from "@material-ui/core";

import './index.css';

import { get_ID_Token }  from '../func/cognito/get_Token';
import CalcTimeDifference from "../func/CalcTimeDifference";
import CalcIntervalS from "../func/CalcIntervalS";
import getUserGroup from "../func/cognito/getUserGroup";

import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

//Components
import LineChartContainer from './LineChart_Container';
import CSVProgess from "./CSV_Progress";
import Alert from "./Alert";

import { useRecoilState } from "recoil";
import { timeRangeState } from "../recoil/Atoms";
import { accordionExpandedState } from "../recoil/Atoms";


const useStyles = makeStyles((theme) => ({
    circleContainer: {
        marginTop: 200,
    },
    selectionContainer_nowrap: {
        display: 'flex',
        marginTop: 20,
        justifyContent: "space-around",
        marginBottom: 20,
    },
    selectionContainer_wrap: {
        display: "flex",
        flexDirection: "column",
	    marginTop: 20,
	    justifyContent: "center",
	    flexWrap: "wrap",
        marginBottom: 20,
    },
    rangeSelection: {
        marginTop: 20,
    },
    rangeController: {
        minWidth: 120,
    },
    bDownLoad: {
        backgroundColor: "#00378b",
        color: "white",
        top: "15%",
    },
    unitInformation: {
        padding: 15,
    },
    informationCard: {
        marginTop: 20,
    },
    fontColor: {
        color: "#00378b",
    },
    loadingCircle: {
        color: 'black',
    },
    AccordionDetails: {
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    DateTimeCard: {
        display: "flex",
        alignItems: "center",
        paddingInline: 5,
        overflow: 'visible',
        justifyContent: "center",
    },
    DateTimeCardWrap: {
        display: "flex",
        alignItems: "center",
        paddingInline: 5,
        overflow: 'visible',
        justifyContent: "center",
        marginTop: 5,
    },
    IconColor: {
        color: "#00378b",
    },
}));

const csvDownload = async (group, device, location, begin, end, interval, onSuccess, onFail) => {
    const windowLocation = window.location.href;
    const pathname = windowLocation.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    var url = "";

    if(isLocal){
       if(interval !== null) {
            url = `http://localhost:8080/rangedata_csv/location/${group}/${device}/${location}?begin=${begin}&end=${end}&interval=${interval}`;
       }
       else{
            url = `http://localhost:8080/rangedata_csv/location/${group}/${device}/${location}?begin=${begin}&end=${end}`;
       }
    }
    else if(isProduction) {
        if(interval !== null) {
            url = `https://server.mypfeifer.com/rangedata_csv/location/${group}/${device}/${location}?begin=${begin}&end=${end}&interval=${interval}`;
        }
        else{
            url = `https://server.mypfeifer.com/rangedata_csv/location/${group}/${device}/${location}?begin=${begin}&end=${end}`;
        }
    }
    else{
        if(interval !== null) {
            url = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/rangedata_csv/location/${group}/${device}/${location}?begin=${begin}&end=${end}&interval=${interval}`;
        }
        else{
            url = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/rangedata_csv/location/${group}/${device}/${location}?begin=${begin}&end=${end}`;
        }
    } 

    let dateString = "" + new Date().getFullYear();
    dateString += new Date().getMonth() < 10 ? "0" + (new Date().getMonth() + 1) : "" + (new Date().getMonth() + 1);
    dateString += new Date().getDate() < 10 ? "0" + new Date().getDate() : "" + new Date().getDate();

    return await axios.get(url, {
        mehtod: 'GET',
        responseType: 'arraybuffer',
        headers: {
            'Authorization': 'Bearer ' + get_ID_Token(),
            'Content-Type' : 'application/json',
        }
    }).then(res => {
        const url = window.URL.createObjectURL(
            new Blob([res.data], {type: "application/octet-stream"}),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `CSV-Files_${location}_${dateString}.zip`
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);

        onSuccess();
    }).catch(() => {
        onFail();
    });
}

const fetch_Mappings = async (group, location) => {
    const windowLocation = window.location.href;
    const pathname = windowLocation.split("/");
    const isLocal = pathname.includes("localhost:3000");
    const isProduction = pathname.includes("mypfeifer.com");

    var url_namemapping = "";
    var url_valuemapping = "";

    if(isLocal){
        url_namemapping = `http://localhost:8080/nameMapping/location/${group}/${location}`;
        url_valuemapping = `http://localhost:8080/valueMapping/location/${group}/${location}`;
    }
    else if(isProduction) {
        url_namemapping = `https://server.mypfeifer.com/nameMapping/location/${group}/${location}`;
        url_valuemapping = `https://server.mypfeifer.com/valueMapping/location/${group}/${location}`;
    }
    else{
        url_namemapping = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/nameMapping/location/${group}/${location}`;
        url_valuemapping = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/valueMapping/location/${group}/${location}`;
    }
    
    return {
        nameMapping: await axios.get(url_namemapping, {
            mehtod: 'GET',
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        }),
        valueMapping: await axios.get(url_valuemapping, {
            mehtod: 'GET',
            headers: {
                'Authorization': 'Bearer ' + get_ID_Token()
            }
        }).then(res => {
            return res.data;
        }).catch((error) => {
            console.log(error);
            return null;
        })
    }
}

//LineChart Component-Function
function LineChartContainerComponent(props){

    const classes = useStyles();
    
    const nowrap = useMediaQuery('(min-width:659px)');

    const [timerange, setTimeRange] = useRecoilState(timeRangeState);
    const [expandedAccordions, setExpandedAccordions] = useRecoilState(accordionExpandedState);
    const [intervalS, setIntervalS] = React.useState(CalcIntervalS(CalcTimeDifference(timerange[0], timerange[1])));
    const [isLoading, setLoading] = React.useState(false);
    const [isFail, setFail] = React.useState(false);

    //TODO: complete download functionality
    const handleDownload = (event) => {
        //event.preventDefault();
        setLoading(true);
        let begin = Math.round(timerange[0].getTime() / 1000);
        let end = Math.round(timerange[1].getTime() / 1000);
        csvDownload(getUserGroup(), props.device, props.locationName, begin, end, intervalS,
        () => {
            setLoading(false);
        }, 
        () => {
            setLoading(false);
            setFail(true);
        });
        
    };

    const [isFinished, setIsFinished] = React.useState(false);
    const [responseData, setResponseData] = React.useState();

    useEffect(() => {
        if(isFinished === false) {
            fetch_Mappings(getUserGroup(), props.locationName).then(res => {
                setResponseData(res);
                setIsFinished(true);
            });
        }
    },[props.locationName, isFinished]);

    const handleTimeChange = (timespan) => {
        timespan[0].setHours(0, 0, 0, 0);
        timespan[1].setHours(23,59,0,0);
        setTimeRange(timespan);
        setIntervalS(CalcIntervalS(CalcTimeDifference(timespan[0], timespan[1])));
    };

    const handleAlertClose = () => {
        setFail(false);
    };

    const handleAccordionChange = (accordion) => (event, isExpanded) => {
        if(isExpanded){
            setExpandedAccordions([...expandedAccordions, accordion]);
        }
        else{
            setExpandedAccordions(expandedAccordions.filter(element => element !== accordion));
        }
    };


    if(isFinished && typeof responseData !== "undefined"){
        return(
            <div className="Rangedata_Container">
                <CSVProgess active={isLoading}/>
                <Alert 
                    active={isFail} 
                    title={"CSV-Download failed!"} 
                    text={"There may be no data in this time span. Try a different time span."}
                    onClose={handleAlertClose}/>
                <div>
                    <div className={nowrap ? classes.selectionContainer_nowrap : classes.selectionContainer_wrap}>
                        <Button className={classes.bDownLoad} variant="contained" onClick={handleDownload}>download csv</Button>
                        <Card raised={true} className={nowrap ? null : classes.informationCard}>
                            <Typography className={classes.unitInformation}>
                                <strong className={classes.fontColor}>Selected Location: </strong> {props.locationName}
                            </Typography>
                        </Card>
                        <Card raised={true} className={nowrap ? classes.DateTimeCard : classes.DateTimeCardWrap}>
                            <DateTimeRangePicker
                                className={classes.DatePicker}
                                onChange={handleTimeChange}
                                value={timerange}
                                format="dd-MM-yyyy"
                                rangeDivider={" to "}
                                calendarAriaLabel="Pick time range"
                                calendarIcon={<CalendarIcon className={classes.IconColor}/>}
                                clearIcon={null}
                            />
                        </Card>
                    </div>
                    {props.units.map(unit => (
                        <Accordion 
                            key={unit.id} 
                            TransitionProps={{ unmountOnExit: true, timeout: 500 }}
                            expanded={expandedAccordions.includes(unit.id)}  
                            onChange={handleAccordionChange(unit.id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                            <Typography sx={{ width: '33%', flexShrink: 0, color: '#00378b'}} className={classes.fontColor}>
                                <strong>{unit.name}</strong>
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.AccordionDetails}>
                                <LineChartContainer
                                    key={Math.random()}
                                    unit={unit.id}
                                    unitName={unit.name}
                                    timerange={timerange}
                                    interval={intervalS}
                                    nameMapping={responseData.nameMapping}
                                    valueMapping={responseData.valueMapping}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        );
    }
    else{
        return(
            <div>
                <div className={classes.circleContainer}>
                    <CircularProgress className={classes.loadingCircle}/>
                </div>
            </div>
        );
    }
}

export default withRouter(LineChartContainerComponent);