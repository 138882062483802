import UserPool from "../../UserPool";

export const get_ID_Token = () => {
    const cognitoUser = UserPool.getCurrentUser();

    let currentSession = {};
    var idToken = "";

    if(cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if(err) {
                console.log(err);
            }
            else if (session.isValid()) {
                currentSession = session;
                idToken = currentSession.idToken.jwtToken;
            }
        });
    }

    return idToken;
};

export const get_Access_Token = () => {
    const cognitoUser = UserPool.getCurrentUser();

    let currentSession = {};
    var accessToken = "";

    if(cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if(err) {
                console.log(err);
            }
            else if (session.isValid()) {
                currentSession = session;
                accessToken = currentSession.accessToken.jwtToken;
            }
        });
    }

    return accessToken;
}

