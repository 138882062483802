//React
import React from "react";
import { withRouter } from "react-router-dom";

//MUI
import {FormControl} from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
//import InputLabel from "@material-ui/core";

const generateArray = (elements, tick) => {

    let array = [];

    let begin = 1;
    let pageCnt = 1;

    // Iterating through the elements of the elements array
    for(let i = 0; i<elements.length; i++) { 
        if ((i !== 0) && ((i % tick) === 0)) { //pushing page entry into the array when the element count is 10
            array.push({
                val: begin-1,
                txt: `Page ${pageCnt}: Value ${begin} to ${i}`
            })
            begin = i+1;
            pageCnt++;
        }
        else if (i+1 === elements.length) { //pushing page entry into the array when the 2nd last element is reached
            array.push({
                val: begin-1,
                txt: `Page ${pageCnt}: Value ${begin} to ${i+1}`
            })
        }
    }

    return array;
}

function PagingDropDown(props){

    const[elements, setElements] = React.useState([]);
    const[active, setActive] = React.useState(0);

    React.useEffect(() => {
        setElements(generateArray(props.elements, props.tick));
    }, [props.elements, props.tick])

    React.useEffect(() => {
        setActive(props.active);
    }, [props.active])

    const handleChange = (event) => {
        props.onChange(event.target.value);
        setActive(event.target.value);
    }

    if (elements !== undefined) {
        return(
            <div className="PagingDropDown">
                <FormControl fullWidth>
                    {/* <InputLabel id="page-dropdown-label">Pages</InputLabel> */}
                    <Select
                        labelId="page-dropdown-label"
                        id="page-dropdown"
                        label="Pages"
                        value={active}
                        onChange={handleChange}
                    >
                        {
                            elements.map((element) => 
                                <MenuItem key={element.val} value={element.val}>{element.txt}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }
    else{
        return <div style={{visibility: "collapse"}}></div>
    }
}

export default withRouter(PagingDropDown);