//React imports
import React from 'react';
import { withRouter } from "react-router-dom";
import { useEffect } from 'react';

import "./Parameter.css";

//SWR
import useSWR from 'swr';

//Axios
import axios from 'axios';

//Recoil
import { useRecoilState } from 'recoil';
import { locationDataState, selectedDeviceState } from "../../recoil/Atoms";

//component imports
import { DeviceDataDropdown, ParameterForm, UserDataDropdown } from '../../components';

//Material-UI imports
import Card from '@material-ui/core/Card';
import { Typography, useEventCallback } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

//func
import getUserGroup from '../../func/cognito/getUserGroup';
import {unitState, unitNameState, userDataState, selectedLocationDataState } from '../../recoil/Atoms';
import { get_ID_Token }  from '../../func/cognito/get_Token';
import ParseUserData from '../../func/ParseUserData';


const fetcher = config => axios.get(config.url, 
    {
        mehtod: 'GET',
        headers: {
            'Access-Control-Allow-Origin': config.origin,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': 'Bearer ' + get_ID_Token()
    }
}).then(res => res.data);

function Parameter(props) {

    const group = getUserGroup();

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000"); 
    const isProduction = pathname.includes("mypfeifer.com");

    const [locationData, setLocationData] = useRecoilState(locationDataState);
    const [selectedLocation, setSelectedLocation] = useRecoilState(selectedLocationDataState);
    const [device, setDevice] = useRecoilState(selectedDeviceState);

    const [unit, setUnit] = useRecoilState(unitState); // should store the global state of the selected unit

    const [unitName, setUnitName] = useRecoilState(unitNameState);

    const [userDataGlobal, setUserDataGlobal] = useRecoilState(userDataState);

    const [key, setKey] = React.useState(Date.now());
    const [keyDeviceSelection, setKeyDeviceSelection] = React.useState(Math.random());

    let config_UserData = {};

    if(isLocal){
        config_UserData['url'] = `http://localhost:8080/userdata/${group}`;
        config_UserData['origin'] = 'http://localhost:8080/userdata/';
    }
    else if(isProduction) {
        config_UserData['url'] = `https://server.mypfeifer.com/userdata/${group}`;
        config_UserData['origin'] = `https://server.mypfeifer.com/userdata/*`;
    }
    else{
        config_UserData['url'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/userdata/${group}`;
        config_UserData['origin'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/userdata/*`;
    }

    useSWR(config_UserData, fetcher, {
        onSuccess: (data, key, config) => {
            var parsedData = ParseUserData(data);
            setLocationData(parsedData.locationDataArray);
            setUserDataGlobal(parsedData.treedata);
            setKey(Math.random());
        }
    });


    const handleSelect = (value) => {
        setUnitName(value.name);
        setUnit(value.name);
        setSelectedLocation(value);
        setKey(Math.random());
        setKeyDeviceSelection(Math.random());
    }

    const handleSelectDevice = (value) => {
        setDevice(value);
    };


    if(unit!=='' && device !== "" && Object.keys(userDataGlobal).length !== 0) {
        return (
            <div className={"Data"}>
                <Card className={"Card"} raised={true}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    {
                        typeof selectedLocation.units !== "undefined"
                        ? <DeviceDataDropdown key={keyDeviceSelection} deviceData={selectedLocation} handleSelect={handleSelectDevice} value={
                            typeof device.name === "undefined" ? "" : device.name
                        }/>
                        :<div/>
                    }
                </Card>
                <ParameterForm key={device.id}  className={"form"} unit={device.id} deviceName={device.name}/>
            </div>
        );
    }
    else if(unit==='' && Object.keys(userDataGlobal).length !== 0) {
        return(
            <div>
                <Card className={"Card"} raised={true}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    {
                        typeof selectedLocation.units !== "undefined"
                        ? <DeviceDataDropdown key={keyDeviceSelection} deviceData={selectedLocation} handleSelect={handleSelectDevice} value={
                            typeof device.name === "undefined" ? "" : device.name
                        }/>
                        :<div/>
                    }
                </Card>
                <div className={"circleContainer"}>
                    <Typography variant="h5" className={"selectionMessage"}>
                        Please select a Device!
                    </Typography>
                </div>
                <div className={"footer"}>
                    <div>
                        © PEIFER Systems GmbH, Satteins, Austria
                    </div>
                </div>
            </div>
        );
    }
    else if(unit!=='' && device === "" && Object.keys(userDataGlobal).length !== 0) {
        return(
            <div>
                <Card className={"Card"} raised={true}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    {
                        typeof selectedLocation.units !== "undefined"
                        ? <DeviceDataDropdown key={keyDeviceSelection} deviceData={selectedLocation} handleSelect={handleSelectDevice} value={
                            device.name === undefined ? "" : `${device.name}`
                        }/>
                        :<div/>
                    }
                </Card>
                <div className={"circleContainer"}>
                    <Typography variant="h5" className={"selectionMessage"}>
                        Please select a Unit!
                    </Typography>
                </div>
                <div className={"footer"}>
                    <div>
                        © PEIFER Systems GmbH, Satteins, Austria
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="circleContainer">
                <CircularProgress className="loadingCircle"/>
            </div>
        );
    }
}

export default withRouter(Parameter);