
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Navigation } from "./components";

import { Data, Log, About, Settings, LoginPage, ForgotPassword, Parameter } from "./pages";
import LegalInformation from "./pages/LegalInformation";
import DataProtection from "./pages/DataProtection";

import isAuthenticated from './func/cognito/isAuthenticated';

import hasWritePermission from "./func/cognito/hasWritePermission";

import { RecoilRoot } from 'recoil';


function App() {

  const location = window.location.pathname;
  const pathname = location.split("/");
  const isLogin = pathname.includes("login");   
  const isLoggedIn = isAuthenticated();
  let hideNavigation = true;

  if(isLoggedIn === false) {
    hideNavigation = true;
  }
  else if(isLogin) {
    hideNavigation = true;
  }
  else{
    hideNavigation = false;
  }

  var parameterRoute;

  if(isLoggedIn) {
    if(hasWritePermission()) {
      parameterRoute = <Route path="/parameter" exact component={() => { return <Parameter/>}} />;
    }
    else {
      parameterRoute = <Route path="/parameter" exact component={() => {return <Redirect to="/"/>}} />;
    }
  }
  else {
    parameterRoute = <Route path="/parameter" exact component={() => {return <Redirect to="/login"/>}} />;
  }

  return (
    <div className="App">
      <RecoilRoot>
        <Router>
          {hideNavigation ? <div/> : <Navigation/>}
          <Switch>
            <Route path="/login" exact component={() => <LoginPage/>}/>
            <Route path="/login/forgotPassword" exact component={() => <ForgotPassword />}/>
            {/* <Route path="/" exact component={() => { return isLoggedIn? <Home /> : <Redirect to="/login" />}} /> */}
            <Route path="/" exact component={() =>  {return isLoggedIn ? <Data/> : <Redirect to="/login" />}} />
            <Route path="/log" exact component={() => {return isLoggedIn ? <Log/> : <Redirect to="/login" />}} />
            <Route path="/about" exact component={() => {return isLoggedIn ? <About/> : <Redirect to="/login" />}} />
            <Route path="/about/LegalInformation" exact component={() => {return isLoggedIn ? <LegalInformation/> : <Redirect to="/login" />}} />
            <Route path="/about/DataProtection" exact component={() => {return isLoggedIn ? <DataProtection/> : <Redirect to="/login" />}} />
            <Route path="/settings" exact component={() => {return isLoggedIn ? <Settings/> : <Redirect to="/login" />}} />
            {parameterRoute}
          </Switch>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
