
import Enumerable from 'linq';

var locationDataArray = [];
function ParseUserData(treedata) {
    let returnObject = {
        id: treedata.id,
        name: treedata.name,
        children: []
    };
    if(Object.keys(treedata).includes("children")){     
        if(Array.isArray(treedata.children)){
            try{
                treedata.children.forEach(element => {
                    returnObject.children.push(parseChildren(
                        element,
                        element.name
                    ));
                });
            }
            catch(e) {
                console.log(e);
            }
            return {
                treedata: returnObject,
                locationDataArray: locationDataArray,
            };
        }
    }
    else
        return null; 
}

function parseChildren(childrenObject, device){
    if(childrenObject.hasOwnProperty("children")){
        let returnObject = {
            id: childrenObject.id,
            name: childrenObject.name
        };
        childrenObject.children.forEach(element => {
            let child = parseChildren(
                element,
                device
            );
            if(typeof child === 'object'){
                if(returnObject.children === undefined) {
                    returnObject.children = [];
                }
                returnObject.children.push(child);
            }
            else if(child === false) {
                const result = Enumerable.from(locationDataArray).where(entry => entry.name === childrenObject.name).toArray();
                if(result.length === 0){
                    let temp = {};
                    temp.name = childrenObject.name;
                    temp.units = childrenObject.children;
                    temp.device = device;
                    locationDataArray.push(temp);
                }
            }
        });
        return returnObject;
    }
    return false;
}

export default ParseUserData;
