import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import Condition from "./Condition";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    FormLabel: {
        color: 'black !important',
        textAlign: 'left'
    },
    FormControl: {
        display: 'flex',
        flexDirection: 'column'
    }
}));


function CheckboxGroup(props) {

    const classes = useStyles();

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        setData(props.dataList);
    }, [props.dataList])

    const handleChange = (event) => {
        props.handleChange(event.target.name, event.target.checked);
    }
    
    return (
        <Condition condition={(typeof data !== 'undefined')}
            true={
                <div className={props.className}>
                    <Typography className={classes.FormLabel}>Receive messages from:</Typography>
                    <div className={classes.FormControl}> 
                        {
                            data.map(element => 
                                <FormControlLabel
                                    label={element.location}
                                    key={element.locationName}
                                    control={
                                        <Checkbox 
                                            checked={element.enabled} 
                                            onChange={handleChange} 
                                            name={data.indexOf(element).toString()}
                                        />
                                    }
                                />
                            )
                        }
                    </div>
                </div>
            }
            false={
                <div>empty param</div>
            }
        />
    );

}

export default withRouter(CheckboxGroup);