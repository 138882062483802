//React
import React from "react";
import { withRouter } from "react-router-dom";

//Material-UI
import { makeStyles } from "@material-ui/styles";
import { FormControl } from "@material-ui/core";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import Enumerable from "linq";


const useStyles = makeStyles((theme) => ({
    rangeController: {
        minWidth: 120,
    },
    container: {
        display: "flex",
        alignItems: 'center',
    }
}));



//LineChart Component-Function
function DeviceDataDropdown(props){

    const classes = useStyles();

    const [entries, setEntries] = React.useState([]);
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        setEntries(props.deviceData.units);
    }, [props.deviceData]);

    React.useEffect(() => {
        if(entries.length === 1) {
            props.handleSelect(entries[0]);
        }
    },[entries])

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleSelection = (event) => {
        event.preventDefault();
        console.log(event.target);
        var result = Enumerable.from(entries).where(entry => entry.name === event.target.value).toArray()[0];
        console.log(result);
        props.handleSelect(result);
    };

    return(
        <div className={classes.container}>
            <FormControl className={classes.rangeController}>
                <InputLabel id="Device-Selection-label">Unit</InputLabel>
                <Select
                    labelId="Device-Selection-label"
                    id="select-Device"
                    value={value}
                    label="Device"
                    onChange={handleSelection}
                >
                    {
                        entries.map(entry => (
                            <MenuItem key={entry.name} value={entry.name}>
                                {entry.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    );
}

export default withRouter(DeviceDataDropdown);