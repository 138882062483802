import React, {useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";

import './index.css';

import { CardContent, Typography } from '@material-ui/core';
import {Button, FormControl } from '@material-ui/core';
import RadioGroup from '../CheckboxGroup';
import getUserGroup from '../../func/cognito/getUserGroup';
import axios from 'axios';
import { get_ID_Token } from '../../func/cognito/get_Token';
import getUserSub from '../../func/cognito/getUserSub';

const controller = new AbortController();

const fetcher = async (request, onSuccess) => {
    let response = [];

    if(request === undefined) {
        throw new Error('Request data is not existing!');
    }

    response  = await axios.get(request, {
        mehtod: 'GET',
        signal: controller,
        headers: {
            'Authorization': 'Bearer ' + get_ID_Token()
        }
    }).then(res => {
        return res.data;
    })
    
    if(response !== null && response !== undefined && response.length !== 0) {
        onSuccess(true, response);
    }
    else {
        onSuccess(false, null);
    }
}

const sender = async (request, requestData, onSuccess, onFailure) => {

    if(request === undefined)
        throw new Error('Request URL is not defined!');

    if(requestData === undefined)
        throw new Error("Request data is not defined!");

    await axios.put(request, requestData,{
        methode: 'put',
        url: request,
        signal: controller,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + get_ID_Token(),
            'Access-Control-Allow-Origin': request,
        }
    })
    .then(res => {
        onSuccess();
    })
    .catch(error => {
        onFailure(error.response.status);
    });

}

function Notification_Settings(props){

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000"); 
    const isProduction = pathname.includes("mypfeifer.com");

    const[fetch, setFetch] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const [updated, setUpdated] = useState([]);
    const [onFailure, setOnFailure] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");

    var get_path = "";
    var put_path = "";

    if(isLocal) {
        get_path = `http://localhost:8080/notification-config/${getUserGroup()}/${getUserSub()}`;
        put_path = `http://localhost:8080/notification-config/edit`;
    }
    else if(isProduction) {
        get_path = `https://server.mypfeifer.com/notification-config/${getUserGroup()}/${getUserSub()}`;
        put_path = `https://server.mypfeifer.com/notification-config/edit`;
    }
    else {
        get_path = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/notification-config/${getUserGroup()}/${getUserSub()}`;
        put_path = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/notification-config/edit`;
    }

    useEffect(() => {
        let cancel = false;
        if(fetch) {
            try{
                fetcher(get_path, (success, data) => {
                    if (cancel)
                        return;
                    if(success){
                        setResponseData(data);
                        setFetch(false);
                    }
                    else {
                        setResponseData(null);
                    }
                });
            }
            catch(e) {
                console.log(e.message);
            }
        }
    
        return () => {
            controller.abort();
            cancel = true;
        }
    }, [fetch, get_path]);

    const [key, setKey] = useState(Math.random());

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    const handleChange = (index, newValue) => {
        responseData[Number.parseInt(index)].enabled = newValue;
        updated.push(Number.parseInt(index));
        setKey(Math.random());
        console.log(responseData);
    }

    const buttonClick = () => {
        let newDataObject = [];
        setUpdated(updated.filter(onlyUnique));
        updated.forEach(index => {
            newDataObject.push({
                'units': responseData[index].units,
                'enabled': responseData[index].enabled,
                'sub': getUserSub(),
            });
        })

        console.log(newDataObject);

        sender(put_path, newDataObject, () => {
            setSuccessMessage("Changed configuration successfully!")
            setOnFailure(false);
        }, (statusCode) => {
            setErrMessage("Something went wrong!");
            setOnFailure(true);
        });
    }


    return (
        <CardContent key={key}>
            <Typography variant="h6">
                Change Notification Settings
            </Typography>
            <form onSubmit={handleChange}>
                <FormControl className="FormContainer">                            
                    <div>
                        <RadioGroup dataList={responseData} className={"RadioGroup"} handleChange={handleChange}/>
                    </div>
                </FormControl>
                
            </form>
            {onFailure
                ?<div >
                    <Typography className={"errMessage"} >{errMessage}</Typography>
                </div>
                :<div >
                    <Typography className={"successMessage"} >{successMessage}</Typography>
                </div>
            }
            <Button 
                type="submit" 
                disableRipple={true} 
                className="Button MuiButton-fullWidth MuiButton-root MuiButtonBase-root MuiButton-contained" 
                fullWidth={true} 
                variant="contained"
                onClick={buttonClick}
            >
                Change Notification Settings
            </Button>
        </CardContent>
    );
}

export default withRouter(Notification_Settings);