

const Timestamp_to_Time_Conversion = (data) => {
   //const array = Array.from(data);
   const array = data;
   for(let i=0; i<array.length; i++)
   {
       let timestamp = array[i].timestamp;

       const date = new Date(timestamp);

       let temp_timestamp = "";

       temp_timestamp = date.toLocaleString();

       array[i].timestamp = temp_timestamp;
   }
   return array;
};

export default Timestamp_to_Time_Conversion;
