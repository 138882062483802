import React from 'react';
import { withRouter } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    home: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 100,
        marginBottom: 100,
    },
    Typography: {
        fontSize: 30,
        marginTop: 50,
    },
    loadingCircle: {
        color: 'white',
    },
}));



function Home(props) {

    const classes = useStyles();

    return (
        <div className={classes.home}>
            <Typography variant="h2">
                Welcome to myPFEIFER!
            </Typography>
        </div>
    );

}

export default withRouter(Home);