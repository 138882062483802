//React imports
import React from 'react';
import { withRouter } from "react-router-dom";
import { useEffect } from 'react';

//SWR
import useSWR from 'swr';

//Axios
import axios from 'axios';

//Recoil
import { useRecoilState } from 'recoil';
import { locationDataState } from "../recoil/Atoms";

//component imports
import { UserDataDropdown } from '../components';
import ValueSheetContainer from '../components/ValueSheetContainer';

//Material-UI imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

//func
import getUserGroup from '../func/cognito/getUserGroup';
import {unitState, unitNameState, userDataState, activeDataPageState, selectedLocationDataState } from '../recoil/Atoms';
import { get_ID_Token }  from '../func/cognito/get_Token';
import LineChartContainerComponent from '../components/RangeData_Container';
import ParseUserData from '../func/ParseUserData';


const useStyles = makeStyles((theme) => ({
    logoBig: {
        maxWidth: 250,
        flexGrow: 1,
    },
    logoMedium: {
        maxWidth: 175,
        justifyContent: 'center',
    },
    logoSmall: {
        marginLeft: 10,
        maxWidth: 125,
    },
    breadcrumbs: {
        display: 'flex',
        justifyContent: 'center',
        
    },
    Card:{
        paddingBlock: 20,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-evenly',
        marginTop: 10,  
        marginBottom: 10,
    },
    primary: {
        display: 'flex',
        color: '#00378b',
    },
    secondary: {
        display: 'flex',
        color: '#404040',
    },
    primarySwitchColor: {
        color: "#00378b",
    },
    TreeView: {
        display: 'flex',
        maxHeight: 200,
        flexGrow: 1,
        maxWidth: 200,
        alignItems: 'center',
    },
    Icon: {
        color: '#00378b',
    },
    button: {
        margin: theme.spacing(1),
        color: 'white',
        backgroundColor: '#00378b',
    },
    radio: {
        color: '#00378b',
    },
    radioCard:{
        padding: 30,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        marginBottom: 10,
        minHeight: 100,
    },
    listitem: {
        height: 25,
        color: '#00378b',
    },
    link: {
        color: "#00378b",
        fontSize: 17,
        display: 'block',
        width: 100,
        textAlign: 'center',
    },
    loadingCircle: {
        color: 'black',
    },
    circleContainer: {
        marginTop: 200,
    },
    footer: {
        position: "absolute",
        bottom: 4,
        height: "2em",
        left: "50%",
        translate: "-50%",
    },
    inhalt: {
        paddingBottom: "2em",
    },
})); 

const fetcher = config => axios.get(config.url, 
    {
        mehtod: 'GET',
        headers: {
            'Access-Control-Allow-Origin': config.origin,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': 'Bearer ' + get_ID_Token()
    }
}).then(res => res.data);

function Data(props) {

    const classes = useStyles();

    const group = getUserGroup();

    const location = window.location.href;
    const pathname = location.split("/");
    const isLocal = pathname.includes("localhost:3000"); 
    const isProduction = pathname.includes("mypfeifer.com");

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: false
    });

    const [locationData, setLocationData] = useRecoilState(locationDataState);
    const [selectedLocation, setSelectedLocation] = useRecoilState(selectedLocationDataState);

    const [unit, setUnit] = useRecoilState(unitState); // should store the global state of the selected unit

    const [unitName, setUnitName] = useRecoilState(unitNameState);

    const [userDataGlobal, setUserDataGlobal] = useRecoilState(userDataState);

    const [activePage, setActivePage] = useRecoilState(activeDataPageState);


    const [key, setKey] = React.useState(Date.now());

    let config_UserData = {};

    if(isLocal){
        config_UserData['url'] = `http://localhost:8080/userdata/${group}`;
        config_UserData['origin'] = 'http://localhost:8080/userdata/';
    }
    else if(isProduction) {
        config_UserData['url'] = `https://server.mypfeifer.com/userdata/${group}`;
        config_UserData['origin'] = `https://server.mypfeifer.com/userdata/*`;
    }
    else{
        config_UserData['url'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/userdata/${group}`;
        config_UserData['origin'] = `http://servertest-env.eba-zwi455pk.eu-central-1.elasticbeanstalk.com/userdata/*`;
    }

    useSWR(config_UserData, fetcher, {
        onSuccess: (data, key, config) => {
            var parsedData = ParseUserData(data);
            setLocationData(parsedData.locationDataArray);
            setUserDataGlobal(parsedData.treedata);
            setKey(Math.random());
        }
    });

    useEffect(() => {
        if(activePage !== undefined && activePage !== null && Object.keys(activePage).length !== 0) {
            setState(activePage);
        }
    }, [activePage]);

    const handleChange = (event) => {
        if(event.target.checked) {
            setState({
                checkedA: true,
                checkedB: false,
            })
            setActivePage({
                checkedA: true,
                checkedB: false,
            });
        }
        else {
            setState({
                checkedA: false,
                checkedB: true,
            })
            setActivePage({
                checkedA: false,
                checkedB: true,
            });
        }
    };

    const handleSelect = (value) => {
        setUnitName(value.name);
        setUnit(value.name);
        setSelectedLocation(value);
        setKey(Math.random());
    }

    if(unit!=='' && Object.keys(userDataGlobal).length !== 0) {
        return (
            <div className="Data">
                <Card className={classes.Card} raised={true}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    <FormControlLabel
                        control={<Switch checked={state.checkedA} disabled={false} onChange={handleChange} name="checkedA" color="primary" />}
                        label={state.checkedA? "Value-View" : "Chart-View"}
                        labelPlacement="end"
                    />
                </Card>
                {
                    state.checkedA
                    ? <ValueSheetContainer key={key} locationName={unitName} device={selectedLocation.device} units={selectedLocation.units}/>
                    : <LineChartContainerComponent key={key} locationName={unitName} device={selectedLocation.device} units={selectedLocation.units}/>
                }
            </div>
        );
    }
    else if(unit==='' && Object.keys(userDataGlobal).length !== 0) {
        return(
            <div>
                <Card className={classes.Card} raised={true}>
                    <UserDataDropdown key={key} userData={locationData} handleSelect={handleSelect} value={
                        selectedLocation.device === undefined ? "" : `${selectedLocation.device} > ${unitName}`
                    }/>
                    <FormControlLabel
                        control={<Switch checked={state.checkedA} disabled={false} onChange={handleChange} name="checkedA" color="primary" />}
                        label={state.checkedA? "Value-View" : "Chart-View"}
                        labelPlacement="end"
                    />
                </Card>
                <div className={classes.circleContainer}>
                    <Typography variant="h5" className={classes.selectionMessage}>
                        Please select a Device!
                    </Typography>
                </div>
                <div className={classes.footer}>
                    <div>
                        © PEIFER Systems GmbH, Satteins, Austria
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={classes.circleContainer}>
                <CircularProgress className={classes.loadingCircle}/>
            </div>
        );
    }
}

export default withRouter(Data);