import React from 'react';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    About: {
        marginTop: 150,
        display: "flex",
        justifyContent: "center",
        
    },
    Card: {
        flexGrow: 1,
        marginInline: 20,
        maxWidth: 1000,
    },
    Container: {
        display: "flex",
        justifyContent: "center",
    },
    contentContainer: {
        flexGrow: 1,
        marginInline: 20,
        maxWidt: 50,
    },
    content: {
        display: "block",
        textAlign: "start",
    },
    title: {
        color: '#00378b',
    },
    subtitle:{
        color: '#00378b',
    },
    devider: {
        position: "relative",
        border: "none",
        height: 0.5,
        marginBottom: 20,
    },
    footer: {
        position: "absolute",
        bottom: 3,
        height: "2em",
        left: "50%",
        translate: "-50%",
    },
    inhalt: {
        paddingBottom: "2em",
    },
    einrücken: {
        paddingLeft:"2em",
    }
}));

function LegalInformation(props) {

    const classes = useStyles();

    return (
        <div className={classes.inhalt}>
            <div className={classes.About}>
                <Card raised={true} className={classes.Card}>
                    <CardHeader title={<Typography className={classes.title} variant="h4">Legal Information</Typography>} 
                        subheader={<Typography className={classes.subtitle}>Information according to §5 TMG (German Telemedia Act ) and §55 (2) RStV (Interstate Broadcasting Treaty)</Typography>}/>
                    <CardContent className={classes.Container}>
                       <div className={classes.contentContainer}>
                           <div className={classes.content}>
                                <dl className={classes.einrücken}>
                                    <dt>The one who is responsible for the contents</dt>
                                    <br/>
                                    <dt>PFEIFER Systems GmbH</dt>
                                    <dt>Sonnenstrasse 8</dt>
                                    <dt>AT-6822 Satteins</dt>
                                    <br/>
                                    <dt>Managing Directors</dt>
                                    <dt>Dr. Martin Jenni, Christian Schlögl</dt>
                                    <br/>
                                    <dt>Tel. +43 5524 5419-0</dt>
                                    <dt>Fax +43 810 9554 196804</dt>
                                    <dt>E-Mail office@pfeifer-systems.at</dt>
                                    <br/>
                                    <dt>FN 341637h, Firmengericht:</dt>
                                    <dt>Landesgericht Feldkirch</dt>
                                    <dt>VAT ID No.: ATU65485067</dt>
                                </dl>
                                <hr className={classes.devider}></hr>
                                <Typography variant="h6"> Information regarding product responsibility</Typography>
                                    <p className={classes.einrücken}>
                                        Customer satisfaction is a central topic of all activities within the PFEIFER Group. 
                                        Therefore we have provided a website that supplies transparent information as to which of our 
                                        companies respectively manufactures or sells each corresponding product. In this respect we would 
                                        like to point out that your respective contractual partner is generally liable for product 
                                        quality.
                                    </p>
                                    <p className={classes.einrücken}>
                                        The limitation only applies in the event of a grossly negligent or wilful breach of duty on our 
                                        part. In the case of simple negligence, we shall only be liable – except in cases 
                                        of injury to life, body or health – only if we breach essential contractual obligations 
                                        (cardinal obligations), whereby the liability is limited to typical contractual and 
                                        foreseeable damage. To the extent that our liability is excluded or limited, this applies 
                                        equally to the personal liability of our employees, co-workers, representatives, bodies and 
                                        vicarious agents.”
                                    </p>
                                <hr className={classes.devider}></hr>
                                <Typography variant="h6"> Copyright</Typography>
                                    <p className={classes.einrücken}>
                                        © PFEIFER Holding GmbH {"&"} Co. KG, Memmingen (Germany). All rights reserved. The contents and the design of our web 
                                        sites is protected by copyright. The text, text components, images, graphics, sound files, animation files, video
                                        files, and their arrangement on the web sites are subject to copyright and other intellectual property protection. 
                                        The contents of this website may not be copied for public or commercial use or distribution, nor may these 
                                        objects be modified or reposted to other sites. Some PFEIFER websites may contain images whose copyrights are 
                                        attributable to third parties.
                                    </p>
                                    
                                <hr className={classes.devider}></hr>
                                <Typography variant="h6"> Warranties, Liability</Typography>
                                    <p className={classes.einrücken}>
                                        The information on these web sites is provided "as is" and without warranty of any kind. While the information provided 
                                        is believed to be accurate, it may include errors or inaccuracies. In no event shall PFEIFER be liable to any person for 
                                        any special, indirect or consequential damages relating to this material, unless caused by gross negligence or intentional 
                                        misconduct. PFEIFER is not responsible for the contents of web sites that are maintained by third parties and therefore 
                                        waives its liability for any links from this web site to other web sites.
                                    </p>
                                    
                                <hr className={classes.devider}></hr>
                           </div>
                       </div>
                    </CardContent>
                </Card>
            
            </div>
        </div>
    );

}

export default withRouter(LegalInformation);